import styled from '@emotion/styled';
import { Alert } from '@mantine/core';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { TTSButton } from './tts-button';
import { v4 as uuidv4 } from 'uuid';
import { useAppContext } from '../core/context';

const Container = styled.div`
    // position: absolute;
    // left: 0;
    z-index: 1;
    text-align: center;

    .game-over {
    }
`;

interface GameOverProps {
    choices?: string[];
}

const GameOver: React.FC<GameOverProps> = ({ choices }) => {

    const context = useAppContext();
    const intl = useIntl();
    const [messageId, setMessageId] = useState(uuidv4());

    useEffect(() => {
        // Update messageId here
        setMessageId(uuidv4());
    }, [choices]); // Depend on message and choices

    const gameOverMessage = intl.formatMessage({
        id: 'game-over-message',
        defaultMessage: 'The End',
        description: 'Message displayed when the game is over',
    });

    const gameOverChoicesMessage = intl.formatMessage({
        id: 'game-over-choice-message',
        defaultMessage: 'Please choose from the following:',
        description: 'Message displayed when the game is over',
    });

    const alertRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (alertRef.current) {
            alertRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    let shownMessage = gameOverMessage;
    let hiddenMessages : string[] = [gameOverChoicesMessage];

    if (choices) {

        shownMessage = gameOverMessage;
        choices.map((choice, index) => {
            hiddenMessages.push((index + 1) + '. ' + choice + '.');
        });
    }

    // const gameOverRef = React.useRef<HTMLDivElement>(null);
    
    // use font awesome icon for error message
    const iconclass = 'fa fa-flag-checkered';
    return <Container>
        <div className={'game-over game-over-' + messageId}>
        <Alert ref={alertRef} icon={<i className={iconclass} />} color="blue">
            <p>{shownMessage}</p>
        </Alert>
        {hiddenMessages.map((message) =>
            <p key={message} style={{display: 'none'}}>{message}</p>
        )}
        {/* <TTSButton id={messageId}
            selector={'.game-over-' + messageId}
            complete={true}
            autoplay={true} 
            hidden={true} /> */}
        </div>
    </Container>;
};

export default GameOver;