import * as idb from '../utils/idb';
import * as Y from 'yjs';
import { MessageTree } from './message-tree';
import { Game } from './types';
import { YGameDoc } from './y-game';

export async function loadFromPreviousVersion(doc: YGameDoc) {
    const serialized = await idb.get('games');
    if (serialized) {
        for (const game of serialized) {
            try {
                if (game.deleted) {
                    continue;
                }
                if (doc.has(game.id)) {
                    continue;
                }
                const messages = new MessageTree();
                for (const m of game.messages) {
                    messages.addMessage(m);
                }
                game.messages = messages;
                importGame(doc, game);
            } catch (e) {
                console.error(e);
            }
        }
    }
}

export function importGame(doc: YGameDoc, game: Game) {
    const ygame = doc.getYGame(game.id, true);

    if (ygame.deleted) {
        return;
    }

    if (game.metadata) {
        for (const key of Object.keys(game.metadata)) {
            if (!ygame.importedMetadata.has(key)) {
                ygame.importedMetadata.set(key, game.metadata[key]);
            }
        }
    } else if (game.title) {
        if (!ygame.importedMetadata.has('title')) {
            ygame.importedMetadata.set('title', game.title);
        }
    }

    if (game.pluginOptions) {
        for (const key of Object.keys(game.pluginOptions)) {
            const [pluginID, option] = key.split('.', 2);
            if (!ygame.pluginOptions.has(key)) {
                ygame.setOption(pluginID, option, game.pluginOptions[key]);
            }
        }
    }

    const messages = game.messages instanceof MessageTree ? game.messages.serialize() : game.messages;

    for (const message of messages) {
        if (ygame.messages.has(message.id)) {
            continue;
        }
        ygame.messages.set(message.id, message);
        ygame.content.set(message.id, new Y.Text(message.content || ''));
        if (message.done) {
            ygame.done.set(message.id, message.done);
        }
    }
}