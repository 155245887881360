import { OptionGroup } from "../core/options/option-group";
import { defaultReactRealTimeVADOptions } from "@ricky0123/vad-react"

export const vadOptions: OptionGroup = {
    id: 'vad',
    name: "Voice Activity Detection",
    hidden: true,
    options: [
        {
            id: "positiveSpeechThreshold",
            defaultValue: defaultReactRealTimeVADOptions.positiveSpeechThreshold,
            resettable: true,
            // scope: "game",
            displayOnSettingsScreen: "speech",
            displayAsSeparateSection: false,
            displayInQuickSettings: {
            name: "Positive Speech Threshold",
            displayByDefault: false,
            label: (value) => "Positive Speech Threshold: " + value.toFixed(1),
            },
            renderProps: (value, options, context) => ({
                type: "slider",
                label: "Positive Speech Threshold: " + value.toFixed(1),
                min: 0,
                max: 1,
                step: 0.01,
                description: context.intl.formatMessage({ defaultMessage: "Speech probability identified as user started speaking." }),
                hidden: true,
            })
        },
        {
            id: "negativeSpeechThreshold",
            defaultValue: defaultReactRealTimeVADOptions.negativeSpeechThreshold,
            resettable: true,
            // scope: "game",
            displayOnSettingsScreen: "speech",
            displayAsSeparateSection: false,
            displayInQuickSettings: {
            name: "Negative Speech Threshold",
            displayByDefault: false,
            label: (value) => "Negative Speech Threshold: " + value.toFixed(1),
            },
            renderProps: (value, options, context) => ({
                type: "slider",
                label: "Negative Speech Threshold: " + value.toFixed(1),
                min: 0,
                max: 1,
                step: 0.01,
                description: context.intl.formatMessage({ defaultMessage: "Speech probability identified as user stopped speaking." }),
                hidden: true,
            })
        },        {
            id: "minSpeechFrames",
            defaultValue: defaultReactRealTimeVADOptions.minSpeechFrames,
            resettable: true,
            // scope: "game",
            displayOnSettingsScreen: "speech",
            displayAsSeparateSection: false,
            displayInQuickSettings: {
            name: "Minimum Speech Frames",
            displayByDefault: false,
            label: (value) => "Minimum Speech Frames: " + value.toFixed(1),
            },
            renderProps: (value, options, context) => ({
                type: "slider",
                label: "Minimum Speech Frames: " + value.toFixed(1),
                min: 1,
                max: 10,
                step: 1,
                description: context.intl.formatMessage({ defaultMessage: "Minimum number of speech frames to be considered valid speech." }),
                hidden: true,
            })
        },
    ],
}