import EventEmitter from "events";
import SSE from "../utils/sse";
import { Game, Message, OpenAIMessage, Parameters, TranscriptionParameters } from "./types";
import { backend } from "../backend";
import { PluginContext } from "../plugins/plugin-context";
import { pluginRunner } from "../plugins/plugin-runner";
import { OptionsManager } from "../options";
import { YGame } from "./y-game";
import { MessageNode } from "./message-tree";

export const defaultModel = 'gpt-4o';

export function isProxySupported() {
    return !!backend.current?.services?.includes('openai');
}

function shouldUseProxy(apiKey: string | undefined | null) {
    return !apiKey && isProxySupported();
}

function getEndpoint(proxied = false) {
    return proxied ? '/gameapi/proxies/openai' : 'https://api.openai.com';
}

export interface OpenAIResponseChunk {
    id?: string;
    done: boolean;
    choices?: {
        delta: {
            content: string;
        };
        index: number;
        finish_reason: string | null;
    }[];
    model?: string;
}

function parseResponseChunk(buffer: any): OpenAIResponseChunk {
    const chunk = buffer.toString().replace('data: ', '').trim();

    if (chunk === '[DONE]') {
        return {
            done: true,
        };
    }

    const parsed = JSON.parse(chunk);

    return {
        id: parsed.id,
        done: false,
        choices: parsed.choices,
        model: parsed.model,
    };
}

export async function createGameCompletion(messages: Message[], parameters: Parameters): Promise<string> {
    const proxied = shouldUseProxy(parameters.apiKey);
    const endpoint = getEndpoint(proxied);

    if (!proxied && !parameters.apiKey) {
        throw new Error('No API key provided');
    }

    let storyHeaders = {};
    if (parameters.storyName) {
        storyHeaders = { 'story-name': parameters.storyName }
    }

    const response = await fetch(endpoint + '/v1/game/completions', {
        method: "POST",
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Authorization': !proxied ? `Bearer ${parameters.apiKey}` : '',
            'Content-Type': 'application/json',
            ...storyHeaders
        },
        body: JSON.stringify({
            "model": parameters.model,
            "messages": messages,
            "temperature": parameters.temperature,
        }),
    });

    const data = await response.json();

    return data.choices[0].message?.content?.trim() || '';
}

export async function createUserTurn(game: Game, yGame: YGame, parentID: string | undefined, messages: Message[], parameters: Parameters, pluginOptions: OptionsManager) {
    const endpoint = getEndpoint(true);

    let storyHeaders = {};
    if (parameters.storyName) {
        storyHeaders = { 'story-name': parameters.storyName }
    }

    let mutatedMessages = [...messages];
    let mutatedParameters = { ...parameters };
    let lastChunkReceivedAt: number = 0;

    const pluginContext = (pluginID: string) => ({
        getOptions: () => {
            return pluginOptions.getAllOptions(pluginID, game.id);
        },

        getCurrentGame: () => {
            return game;
        },

        createGameCompletion: async (messages: Message[], _parameters: Parameters) => {
            return await createGameCompletion(messages, _parameters);
        },

        setGameTitle: async (title: string) => {
            yGame.title = title;
        },
    } as PluginContext);

    await pluginRunner("preprocess-model-input", pluginContext, async plugin => {
        const output = await plugin.preprocessModelInput(mutatedMessages, mutatedParameters);
        mutatedMessages = output.messages;
        mutatedParameters = output.parameters;
        lastChunkReceivedAt = Date.now();
    });

    // send no more than the last two messages to avoid HTTP 413 (Request Entity Too Large) errors
    const lastTwoMessages = mutatedMessages.slice(-2);
    // set the message.content to an empty string for all messages by the assistant role
    lastTwoMessages.forEach(m => {
        if (m.role === 'assistant') {
            m.content = '';
        }
    });

    const response = await fetch(endpoint + '/v1/game/completions', {
        method: "POST",
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Authorization': '',
            'Content-Type': 'application/json',
            ...storyHeaders
        },
        body: JSON.stringify({
            "messages": lastTwoMessages,
            // "model": parameters.model,
            // "temperature": parameters.temperature,
        }),
    });

    lastChunkReceivedAt = Date.now();

    // if the response status is not 200, then the response is an error
    if (response.ok) {
        const data = await response.json();

        if (!data) {
            throw new Error('No data received. Please try again later.');
        }

        if (data.action) {
            // will be handeled by the GameManager
            return data;
            
        } else {

            const message: Message = {
                id: data.id,
                parentID,
                gameID: game.id,
                timestamp: Date.now(),
                role: 'assistant',
                content: data.content,
                choices: data.choices,
            };

            // I think the content is already set by now, but revisit:
            // yGame.setMessageContent(message.id, message.content);

            await pluginRunner("postprocess-model-output", pluginContext, async plugin => {
                const output = await plugin.postprocessModelOutput({
                    role: 'assistant',
                    content: message.content,
                }, mutatedMessages, mutatedParameters, true);
        
                message.content = output.content;
            });
            
            return message;
        }

    } else {
        const error = await response.text();
        // console.error(error);

        // delete the last user message
        const lastUserMessage = messages.filter(m => m.role === 'user').pop();
        if (lastUserMessage) {
            yGame.deleteMessage(lastUserMessage.id);
        }

        throw new Error(error);
    }
}

export async function createStreamingGameCompletion(messages: Message[], parameters: Parameters) {
    const emitter = new EventEmitter();

    const proxied = shouldUseProxy(parameters.apiKey);
    const endpoint = getEndpoint(proxied);

    if (!proxied && !parameters.apiKey) {
        throw new Error('No API key provided');
    }

    let storyHeaders = {};
    if (parameters.storyName) {
        storyHeaders = { 'story-name': parameters.storyName }
    }

    const eventSource = new SSE(endpoint + '/v1/game/completions', {
        method: "POST",
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Authorization': !proxied ? `Bearer ${parameters.apiKey}` : '',
            'Content-Type': 'application/json',
            ...storyHeaders
        },
        payload: JSON.stringify({
            "model": parameters.model,
            "messages": messages,
            "temperature": parameters.temperature,
            "stream": true,
        }),
    }) as SSE;

    let contents = '';

    eventSource.addEventListener('error', (event: any) => {
        if (!contents) {
            let error = event.data;
            try {
                error = JSON.parse(error).error.message;
            } catch (e) { }
            emitter.emit('error', error);
        }
    });

    eventSource.addEventListener('message', async (event: any) => {
        if (event.data === '[DONE]') {
            emitter.emit('done');
            return;
        }

        try {
            const chunk = parseResponseChunk(event.data);
            if (chunk.choices && chunk.choices.length > 0) {
                contents += chunk.choices[0]?.delta?.content || '';
                emitter.emit('data', contents);
            }
        } catch (e) {
            console.error(e);
        }
    });

    eventSource.stream();

    return {
        emitter,
        cancel: () => eventSource.close(),
    };
}

export async function createTranscription(speechFile: File, parameters: TranscriptionParameters, lastReplyID): Promise<string> {
    console.log("createTranscription parameters: %s", JSON.stringify(parameters));

    const proxied = shouldUseProxy(parameters.apiKey);
    const endpoint = getEndpoint(proxied);

    if (!proxied && !parameters.apiKey) {
        throw new Error('No API key provided');
    }

    // Create a new instance of FormData
    const formData = new FormData();

    const model = parameters.model;

    // Append the file to the new FormData instance, if it exists
    formData.append('file', speechFile);

    // Append other fields
    formData.append('model', model); 
    formData.append('lastReplyID', lastReplyID);

    const headers = {
        'Accept': 'application/json, text/plain, */*',
        'Authorization': !proxied ? `Bearer ${parameters.apiKey}` : '',
    }

    const response = await fetch(endpoint + '/v1/audio/transcriptions', {
        method: "POST",
        headers: headers,
        body: formData,
    });

    const transcription = await response.json();

    console.log("Transcribed: %s", transcription);

    return transcription?.text;
}

// export async function createTranscriptionFromBlob(blob: Blob, parameters: TranscriptionParameters): Promise<string> {
//     console.log("createTranscription parameters: %s", JSON.stringify(parameters));

//     const proxied = shouldUseProxy(parameters.apiKey);
//     const endpoint = getEndpoint(proxied);

//     if (!proxied && !parameters.apiKey) {
//         throw new Error('No API key provided');
//     }

//     // Create a new instance of FormData
//     const formData = new FormData();

//     // const blob = new Blob([wavBuffer], { type: 'application/octet-stream' });
//     const model = parameters.model;

//     // Append the file to the new FormData instance, if it exists
//     formData.append('file', blob, "spoken.mp3");

//     // Append other fields
//     formData.append('model', model);

//     const headers = {
//         'Accept': 'application/json, text/plain, */*',
//         'Authorization': !proxied ? `Bearer ${parameters.apiKey}` : '',
//     }

//     const response = await fetch(endpoint + '/v1/mp3/transcriptions', {
//         method: "POST",
//         headers: headers,
//         body: formData,
//     });

//     const transcription = await response.json();

//     console.log("Transcribed: %s", transcription);

//     return transcription?.text;
// }

export const maxTokensByModel = {
    "gpt-3.5-turbo": 4096,
    "gpt-4-turbo": 128000,
    "gpt-4o": 128000,
    // "gpt-4": 8192,
    // "gpt-4-0613": 8192,
    // "gpt-4-32k": 32768,
    // "gpt-4-32k-0613": 32768,
    // "gpt-3.5-turbo-16k": 16384,
    // "gpt-3.5-turbo-0613": 4096,
    // "gpt-3.5-turbo-16k-0613": 16384,
};
