// Choices component is a functional component that renders a list of choices with a button for each choice. The component takes a list of choices and a callback function as props. The callback function is called when a choice is clicked. The component uses the map() method to iterate over the list of choices and render a button for each choice. The button has an onClick event handler that calls the callback function with the choice as an argument. The component returns a div element with a list of buttons. The component is used in the Story component to render the choices for the user to select from
import styled from '@emotion/styled';
import { Button, List } from '@mantine/core';
import { useAppContext } from '../core/context';
import { useIntl } from 'react-intl';
import { useCallback, useEffect, useMemo, useState } from 'react';
import MessageError from './message-error';
import GameOver from './game-over';
import { useTTS } from '../core/tts/use-tts';

const ChoicesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    max-width: 50rem;
    // width: 100rem;
    margin: auto;
    // text-align: left;

    .button-inner {
        // max-width: 50rem;
        // margin: auto;
        // text-align: left;
        justify-content: left;
    }

    .button-label {
        white-space: normal;
    }
`;

export interface ChoicesProps {
    choices: string[];
    className?: string;
}

export function Choices(props: ChoicesProps & React.ComponentPropsWithoutRef<'button'>) {
    const context = useAppContext();

    const intl = useIntl();
    // const navigate = useNavigate();
    const { state } = useTTS();

    const [choicesEnabled, setChoicesEnabled] = useState(true);
    const [shownChoices, setShownChoices] = useState(props.choices);
    const [gameOver, setGameOver] = useState(false);

    useEffect(() => {
        if (props.choices && props.choices.length >= 1 && props.choices[0] === '[end]') {
            setGameOver(true);

            // omit the first choice from the shown choices
            setShownChoices(props.choices.slice(1));
            
        } else {
            setGameOver(false);
            setShownChoices(props.choices);
        }
    }, [props.choices]);

    const onChoiceClick = useCallback(async (choice: string) => {
        console.log(`onChoiceClick: ${choice}`);

        // disable the choices buttons
        setChoicesEnabled(false);

        // send as the user's message
        await context.onNewMessage(choice);

        // reenable the choices buttons
        setChoicesEnabled(true);

    }, [choicesEnabled, gameOver, shownChoices]);

    const classes = useMemo(() => {
        const classes = ['prose', 'dark:prose-invert', 'choices'];

        if (props.className) {
            classes.push(props.className);
        }

        return classes;
    }, [props.className])

    const elem = useMemo(() => {
        return <ChoicesContainer>
            {/* <div className="inner"> */}
            {/* <FormattedMessage defaultMessage="Choices:" description="Prompt to choose an option" /> */}
            <MessageError message={context.messageError} choices={shownChoices} />
            {gameOver && <GameOver choices={shownChoices} />}
            <Button.Group orientation='vertical'>
                {shownChoices.map((choice, index) =>
                (<Button
                    // styles={{inner: {justifyContent: 'left'}}}
                    classNames={{
                        inner: 'button-inner',
                        label: 'button-label'
                    }}
                    variant='subtle'
                    disabled={!choicesEnabled}
                    key={index}
                    leftIcon={<span>{index + 1}.</span>}
                    onClick={() => onChoiceClick(choice)}>
                    {choice}
                </Button>
                )
                )}
            </Button.Group>
            {/* </div> */}
        </ChoicesContainer>;
    }, [props.choices, shownChoices, classes, choicesEnabled, intl, context.messageError]);

    if (!props.choices.length || props.choices.length === 0) {
        // no choices to show
        return null;
    }

    if (state && state.playing && !(context.messageError)) { 
        // wait for the tts to stop playing before showing choices
        // unless there is an error message
        return null;
    }

    return elem;
}


