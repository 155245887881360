import * as Y from 'yjs';
import { Game, Message, Story } from './types';
import EventEmitter from 'events';
import { v4 as uuidv4 } from 'uuid';
import { MessageTree } from './message-tree';

const METADATA_KEY = 'metadata';
const IMPORTED_METADATA_KEY = 'imported-metadata';
const PLUGIN_OPTIONS_KEY = 'plugin-options';
const MESSAGES_KEY = 'messages';
const CONTENT_KEY = 'messages:content';
const DONE_KEY = 'messages:done';

export class YGame {
    private callback: any;
    private pendingContent = new Map<string, string>();
    private prefix = 'game.' + this.id + '.';

    public static from(root: Y.Doc, id: string) {
        // const id = data.get('metadata').get('id') as string;
        return new YGame(id, root);
    }

    constructor(public readonly id: string, public root: Y.Doc) {
        this.purgeDeletedValues();
    }

    public observeDeep(callback: any) {
        this.callback = callback;
        this.metadata?.observeDeep(callback);
        this.pluginOptions?.observeDeep(callback);
        this.messages?.observeDeep(callback);
        this.content?.observeDeep(callback);
        this.done?.observeDeep(callback);
    }

    public get deleted(): boolean {
        return this.metadata.get('deleted') || false;
    }

    public get metadata(): Y.Map<any> {
        return this.root.getMap<any>(this.prefix + METADATA_KEY);
    }

    public get importedMetadata(): Y.Map<any> {
        return this.root.getMap<any>(this.prefix + IMPORTED_METADATA_KEY);
    }

    public get pluginOptions(): Y.Map<any> {
        return this.root.getMap<any>(this.prefix + PLUGIN_OPTIONS_KEY);
    }

    public get messages(): Y.Map<Message> {
        return this.root.getMap<Message>(this.prefix + MESSAGES_KEY);
    }

    public get content(): Y.Map<Y.Text> {
        return this.root.getMap<Y.Text>(this.prefix + CONTENT_KEY);
    }

    public get done(): Y.Map<boolean> {
        return this.root.getMap<boolean>(this.prefix + DONE_KEY);
    }

    public get title() {
        return (this.metadata.get('title') as string) || (this.importedMetadata.get('title') as string) || null;
    }

    public set title(value: string | null) {
        if (value) {
            this.metadata.set('title', value);
        }
    }

    public get story() {
        return (this.metadata.get('story') as Story) || (this.importedMetadata.get('story') as Story) || null;
    }

    public set story(value: Story | null) {
        if (value) {
            this.metadata.set('story', value);
        }
    }

    public setPendingMessageContent(messageID: string, value: string) {
        this.pendingContent.set(messageID, value);
        this.callback?.();
    }

    public setMessageContent(messageID: string, value: string) {
        this.pendingContent.delete(messageID);
        this.content.set(messageID, new Y.Text(value));
    }

    public deleteMessage(messageID: string) {
        this.pendingContent.delete(messageID);
        this.messages.delete(messageID);
        this.content.delete(messageID);
        this.done.delete(messageID);
    }

    public getMessageContent(messageID: string) {
        return this.pendingContent.get(messageID) || this.content.get(messageID)?.toString() || "";
    }

    public onMessageDone(messageID: string) {
        this.done.set(messageID, true);
    }

    public getOption(pluginID: string, optionID: string): any {
        const key = pluginID + "." + optionID;
        return this.pluginOptions?.get(key) || null;
    }

    public setOption(pluginID: string, optionID: string, value: any) {
        const key = pluginID + "." + optionID;
        return this.pluginOptions.set(key, value);
    }

    public hasOption(pluginID: string, optionID: string) {
        const key = pluginID + "." + optionID;
        return this.pluginOptions.has(key);
    }

    public delete() {
        if (!this.deleted) {
            this.metadata.clear();
            this.pluginOptions.clear();
            this.messages.clear();
            this.content.clear();
            this.done.clear();
        } else {
            this.purgeDeletedValues();
        }
    }

    private purgeDeletedValues() {
        if (this.deleted) {
            if (this.metadata.size > 1) {
                for (const key of Array.from(this.metadata.keys())) {
                    if (key !== 'deleted') {
                        this.metadata.delete(key);
                    }
                }
            }
            if (this.pluginOptions.size > 0) {
                this.pluginOptions.clear();
            }
            if (this.messages.size > 0) {
                this.messages.clear();
            }
            if (this.content.size > 0) {
                this.content.clear();
            }
            if (this.done.size > 0) {
                this.done.clear();
            }
        }
    }
}

export class YGameDoc extends EventEmitter {
    public root = new Y.Doc();
    // public games = this.root.getMap<Y.Map<any>>('games');
    // public deletedGameIDs = this.root.getArray<string>('deletedGameIDs');
    public deletedGameIDsSet = new Set<string>();
    public options = this.root.getMap<Y.Map<any>>('options');
    private yGames = new Map<string, YGame>();

    private observed = new Set<string>();

    constructor() {
        super();

        this.root.whenLoaded.then(() => {
            const gameIDs = Array.from(this.root.getMap('games').keys());
            for (const id of gameIDs) {
                this.observeGame(id);
            }
        });
    }

    private observeGame(id: string, yGame = this.getYGame(id)) {
        if (!this.observed.has(id)) {
            yGame?.observeDeep(() => this.emit('update', id));
            this.observed.add(id);
        }
    }

    // public set(id: string, game: YGame) {
    //     this.games.set(id, game.data);

    //     if (!this.observed.has(id)) {
    //         this.getYGame(id)?.observeDeep(() => this.emit('update', id));
    //         this.observed.add(id);
    //     }
    // }

    public get gameIDMap() {
        return this.root.getMap('gameIDs');
    }

    public getYGame(id: string, expectContent = false) {
        let yGame = this.yGames.get(id);

        if (!yGame) {
            yGame = YGame.from(this.root, id);
            this.yGames.set(id, yGame);
        }

        if (expectContent && !this.gameIDMap.has(id)) {
            this.gameIDMap.set(id, true);
        }

        this.observeGame(id, yGame);

        return yGame;
    }

    public delete(id: string) {
        this.getYGame(id)?.delete();
    }

    public has(id: string) {
        return this.gameIDMap.has(id) && !YGame.from(this.root, id).deleted;
    }

    public getGameIDs() {
        return Array.from(this.gameIDMap.keys());
    }

    public getAllYGames() {
        return this.getGameIDs().map(id => this.getYGame(id)!);
    }

    public transact(cb: () => void) {
        return this.root.transact(cb);
    }

    public addMessage(message: Message) {
        const game = this.getYGame(message.gameID, true);

        if (!game) {
            throw new Error('Game not found');
        }

        this.transact(() => {
            game.messages.set(message.id, {
                ...message,
                content: '',
            });
            game.content.set(message.id, new Y.Text(message.content || ''));
            if (message.done) {
                game.done.set(message.id, message.done);
            }
        });
    }

    public deleteMessage(message: Message) {
        const game = this.getYGame(message.gameID, true);

        if (!game) {
            throw new Error('Game not found');
        }

        this.transact(() => {
            game.deleteMessage(message.id);
        });
    }

    public createYGame(id = uuidv4()) {
        // return new YGame(id, this.root);
        // this.set(id, game);
        return id;
    }

    public getMessageTree(gameID: string): MessageTree {
        const tree = new MessageTree();
        const game = this.getYGame(gameID);

        game?.messages?.forEach(m => {
            try {
                const content = game.getMessageContent(m.id);
                const done = game.done.get(m.id) || false;
                tree.addMessage(m, content, done);
            } catch (e) {
                console.warn(`failed to load message ${m.id}`, e);
            }
        });

        return tree;
    }

    public getMessagesPrecedingMessage(gameID: string, messageID: string) {
        const tree = this.getMessageTree(gameID);
        const message = tree.get(messageID);

        if (!message) {
            throw new Error("message not found: " + messageID);
        }

        const messages: Message[] = message.parentID
            ? tree.getMessageChainTo(message.parentID)
            : [];

        return messages;
    }

    public deleteReplies(gameID: string, messageID: string) {
        const tree = this.getMessageTree(gameID);
        const message = tree.get(messageID);
        const game = this.getYGame(gameID, true);

        if (!message) {
            throw new Error("message not found: " + messageID);
        }

        if (!game) {
            throw new Error("game not found: " + gameID);
        }

        for (const reply of Array.from(message.replies)) {
            game.deleteMessage(reply.id);
        }
    }

    public getGame(id: string): Game {
        const game = this.getYGame(id);
        const tree = this.getMessageTree(id);
        return {
            id,
            messages: tree,
            story: game.story,
            title: game.title,
            metadata: {
                ...game.importedMetadata.toJSON(),
                ...game.metadata.toJSON(),
            },
            pluginOptions: game?.pluginOptions?.toJSON() || {},
            deleted: !game.deleted,
            created: tree.first?.timestamp || 0,
            updated: tree.mostRecentLeaf()?.timestamp || 0,
        }
    }

    public getOption(pluginID: string, optionID: string): any {
        const key = pluginID + "." + optionID;
        return this.options.get(key);
    }

    public setOption(pluginID: string, optionID: string, value: any) {
        const key = pluginID + "." + optionID;
        return this.options.set(key, value);
    }
}