import { PluginDescription } from "../core/plugins/plugin-description";
import TTSPlugin from "../core/tts/tts-plugin";
import { Voice } from "../core/tts/types";
import { defaultOpenAITTSVoiceID, defaultVoiceList } from "./openaitts-defaults";
import { backend } from "../core/backend";

function isProxySupported() {
    return !!backend.current?.services?.includes('openai');
}

function shouldUseProxy(apiKey: string | undefined | null) {
    return !apiKey && isProxySupported();
}

function getEndpoint(proxied = false) {
    return proxied ? '/gameapi/proxies/openai' : 'https://api.openai.com';
}

function getVoiceFromOpenAITTSVoiceObject(v: any) {
    return {
        service: "openaitts",
        id: v.voice_id,
        name: v.name,
        sampleAudioURL: v.preview_url,
    };
}

export interface OpenAITTSPluginOptions {
    apiKey: string | null;
    voice: string;
    customVoiceID: string | null;
    hd: boolean;
    speed: number;
}

/**
 * Plugin for integrating with OpenAITTS Text-to-Speech service.
 * 
 * If you want to add a plugin to support another cloud-based TTS service, this is a good example
 * to use as a reference.
 */
export default class OpenAITTSPlugin extends TTSPlugin<OpenAITTSPluginOptions> {
    static voices: Voice[] = defaultVoiceList.map(getVoiceFromOpenAITTSVoiceObject);

    private proxied = shouldUseProxy(this.options?.apiKey);
    private endpoint = getEndpoint(this.proxied);

    /**
     * The `describe` function is responsible for providing a description of the OpenAITTSPlugin class,
     * including its ID, name, and options.
     * 
     * This information is used to configure the plugin and display its settings on the user interface.
     *
     * In this specific implementation, the `describe` function returns an object containing the plugin's
     * ID ("openaitts"), name ("OpenAITTS Text-to-Speech"), and an array of options that can be
     * configured by the user. These options include the API key, voice selection, and custom voice ID.
     * 
     * Each option has its own set of properties, such as default values, display settings, and validation
     * rules, which are used to render the plugin's settings on the user interface and ensure proper
     * configuration.
     */
    describe(): PluginDescription {
        return {
            id: "openaitts",
            name: "OpenAI Text-to-Speech",
            options: [
                // {
                //     id: "apiKey",
                //     defaultValue: null,

                //     displayOnSettingsScreen: "speech",
                //     displayAsSeparateSection: true,
                //     resettable: false,

                //     renderProps: (value, options, context) => ({
                //         type: "password",
                //         label: context.intl.formatMessage({ defaultMessage: "Your OpenAITTS API Key" }),
                //         placeholder: context.intl.formatMessage({ defaultMessage: "Paste your API key here" }),
                //         description: <>
                //             <p>
                //                 <FormattedMessage
                //                     defaultMessage="Give ChatGPT a realisic human voice by connecting your OpenAITTS account (preview the available voices below). <a>Click here to sign up.</a>"
                //                     values={{
                //                         a: (chunks: any) => <a href="https://www.openaitts.io" target="_blank" rel="noreferrer">{chunks}</a>
                //                     }} />
                //             </p>
                //             <p>
                //                 <FormattedMessage defaultMessage="You can find your API key by clicking your avatar or initials in the top right of the OpenAITTS website, then clicking Profile. Your API key is stored only on this device and never transmitted to anyone except OpenAITTS." />
                //             </p>
                //         </>,
                //         hidden: options.getOption('tts', 'service') !== 'openaitts',
                //     }),
                // },
                {
                    id: "voice",
                    defaultValue: defaultOpenAITTSVoiceID,
                    scope: "game",
                    displayOnSettingsScreen: "speech",
                    displayAsSeparateSection: true,

                    renderProps: (value, options, context) => {
                        return {
                            type: "select",
                            label: "Voice",
                            disabled: !isProxySupported(),
                            hidden: options.getOption('tts', 'service') !== 'openaitts',
                            options: [
                                ...OpenAITTSPlugin.voices.map(v => ({
                                    label: v.name!,
                                    value: v.id,
                                })),
                                {
                                    label: context.intl.formatMessage({ defaultMessage: "Custom Voice ID" }),
                                    value: 'custom',
                                }
                            ],
                        };
                    },
                },
                {
                    id: "customVoiceID",
                    defaultValue: null,
                    scope: "game",
                    displayOnSettingsScreen: "speech",
                    renderProps: (value, options, context) => {
                        return {
                            type: "text",
                            label: context.intl.formatMessage({ defaultMessage: "Custom Voice ID" }),

                            // hide when custom voice is not selected:
                            disabled: options.getOption('openaitts', 'voice') !== 'custom',
                            hidden: options.getOption('openaitts', 'voice') !== 'custom' || options.getOption('tts', 'service') !== 'openaitts',
                        };
                    },
                    validate: (value, options) => options.getOption('openaitts', 'voice') !== 'custom',
                },
                {
                    id: "hd",
                    defaultValue: false,
                    displayOnSettingsScreen: "speech",
                    renderProps: (value, options, context) => {
                        return {
                            type: "checkbox",
                            label: context.intl.formatMessage({ defaultMessage: "Use high definition text-to-speech models." }),
                            // hide when openaitts is not selected:
                            disabled: options.getOption('tts', 'service') !== 'openaitts',
                            hidden: true, // && options.getOption('tts', 'service') !== 'openaitts',
                        };
                    },
                },
                {
                    id: "speed",
                    defaultValue: 1,
                    resettable: false,
                    scope: "user",
                    displayOnSettingsScreen: "speech",
                    displayInQuickSettings: {
                    name: "Speech Speed",
                    displayByDefault: false,
                    label: (value) => "Speed: " + value.toFixed(2),
                    },
                    renderProps: (value, options, context) => ({
                        type: "slider",
                        label: "Speech Speed: " + value.toFixed(2),
                        min: 0.25,
                        max: 4.0,
                        step: 0.25,
                        description: context.intl.formatMessage({ defaultMessage: "Text-to-speech speed." }),
                        disabled: options.getOption('tts', 'service') !== 'openaitts',
                        hidden: options.getOption('tts', 'service') !== 'openaitts',
                    })
                },
            ],
        }
    }

    /**
     * Initializes the plugin by fetching available voices.
     */
    async initialize() {
        await super.initialize();
    }

    /**
     * Fetches and returns the available voices from OpenAITTS API.
     * This function stores the list of voices in a static variable, which is used elsewhere.
     * @returns {Promise<Voice[]>} A promise that resolves to an array of Voice objects.
     */
    async getVoices(): Promise<Voice[]> {
        // NOTE: Open AI currenty doesn't support this API call so just returns the default list 
        // hardcoded in openaitts-defaults.tsx

        // const response = await fetch(`${this.endpoint}/v1/voices`, {
        //     headers: this.createHeaders(),
        // });
        // const json = await response.json();
        // if (json?.voices?.length) {
        //     OpenAITTSPlugin.voices = json.voices.map(getVoiceFromOpenAITTSVoiceObject);
        // }
        return OpenAITTSPlugin.voices;
    }

    /**
     * Returns the current voice based on the plugin options.
     * @returns {Promise<Voice>} A promise that resolves to a Voice object.
     */
    async getCurrentVoice(): Promise<Voice> {
        let voiceID = this.options?.voice;

        // If using a custom voice ID, construct a voice object with the provided voice ID
        if (voiceID === 'custom' && this.options?.customVoiceID) {
            return {
                service: 'openaitts',
                id: this.options.customVoiceID,
                name: 'Custom Voice',
            };
        }

        // Search for a matching voice object
        const voice = OpenAITTSPlugin.voices.find(v => v.id === voiceID);
        if (voice) {
            return voice;
        }

        // If no matching voice is found, return a default Voice object
        // with the defaultOpenAITTSVoiceID and 'openaitts' as the service
        return {
            service: 'openaitts',
            id: defaultOpenAITTSVoiceID,
        };
    }

    /**
     * Returns the current speed based on the plugin options.
     * @returns {Promise<number>} A promise that resolves to a Voice object.
     */
    async getCurrentSpeed(): Promise<number> {
        return this.options?.speed || 1;
    }

    /**
     * Converts the given text into speech using the specified voice and returns an audio file as a buffer.
     * @param {string} text The text to be converted to speech.
     * @param {Voice} [voice] The voice to be used for text-to-speech conversion. If not provided, the current voice will be used.
     * @returns {Promise<ArrayBuffer | null>} A promise that resolves to an ArrayBuffer containing the audio data, or null if the conversion fails.
     */
    async speakToBuffer(text: string, voice?: Voice): Promise<ArrayBuffer | null> {
        if (!voice) {
            voice = await this.getCurrentVoice();
        }

        const url = this.endpoint + '/v1/audio/speech';

        const speed = await this.getCurrentSpeed();

        const response = await fetch(url, {
            headers: this.createHeaders(),
            method: 'POST',
            body: JSON.stringify({
                model: this.options?.hd ? 'tts-1-hd' : 'tts-1',
                voice: voice.id,
                speed: speed,
                response_format: 'mp3',
                input: text,
            }),
        });

        if (response.ok) {
            return await response.arrayBuffer();
        } else {
            return null;
        }
    }

    /**
     * Creates and returns the headers required for OpenAITTS API requests.
     */
    private createHeaders(): Record<string, string> {
        const headers: Record<string, string> = {
            'Content-Type': 'application/json',
        }

        if (!this.proxied && this.options?.apiKey) {
            headers['Authorization'] = `Bearer ${this.options.apiKey}`;
        }

        return headers;
    }
}