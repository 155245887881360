import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Carousel } from '@mantine/carousel';
import { Story } from '../core/game/types';
import { Button, Modal, Paper, Space, Text, Title, createStyles, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { coverImage } from './pages/game';
import { useOption } from '../core/options/use-option';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../core/context';
import { SystemPromptPlugin } from '../plugins/system-prompt';
import { useAppDispatch, useAppSelector } from '../store';
import { setMessage } from '../store/message';
import { useTTS } from '../core/tts/use-tts';
import { openContentRatingPanel } from '../store/settings-ui';
import styled from '@emotion/styled';
import { closeModals, openStoryPreviewModal, selectModal } from '../store/ui';
import { FormattedMessage, useIntl } from 'react-intl';

const useStyles = createStyles((theme) => ({
    card: {
        height: 440,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        transition: 'transform 0.2s ease-in-out, border 0.2s ease-in-out',
        transformOrigin: 'center', // Add this line
        border: '2px',

        '&:hover': {
            transform: 'scale(1.02)',
            // border: '2px solid #ff0',
            border: '0px',
        },
    },

    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontWeight: 900,
        color: theme.white,
        lineHeight: 1.2,
        fontSize: 32,
        marginTop: theme.spacing.xs,
    },

    category: {
        color: theme.white,
        opacity: 0.7,
        fontWeight: 700,
        textTransform: 'uppercase',
    },

    preview: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        border: '2px',
        // text color should be white
        color: theme.white,

    },
}));

interface CardProps {
    story: Story;
    onStoryClick: (story: Story) => void;
}

function Card({ story, onStoryClick }: CardProps) {
    const { classes } = useStyles();

    const image = coverImage(story);
    const { title, category } = story;

    return (
        <Paper
            shadow="md"
            p="xl"
            radius="md"
            sx={{ backgroundImage: `url(${image})` }}
            className={classes.card}
            onClick={() => onStoryClick(story)}
        >
            <div>
                <Text className={classes.category} size="xs">
                    {category}
                </Text>
                <Title order={3} className={classes.title}>
                    {title}
                </Title>
            </div>
            {/* <Button variant="default" onClick={() => onStoryClick(story)}>
                Play game
            </Button> */}
        </Paper>
    );
}

interface PreviewProps {
    story: Story | null;
    onStartGame: (story: Story | null) => void;
}

export function StoryPreviewModal(props: PreviewProps) {
    const modal = useAppSelector(selectModal);
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const onClose = useCallback(() => dispatch(closeModals()), [dispatch]);

    const { classes } = useStyles();

    const image = coverImage(props.story);

    const theme = useMantineTheme();
    const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);

    if (!(props?.story)) {
        return null;
    }

    const { title, category, author, description, contentRating } = props.story;
    // if contentRating is defined, show it and add a plus sign to the end
    // if it is zero, show it as "All ages"
    // if it is undefined, show an empty string
    const contentRatingString = contentRating ? contentRating.toString() + "+" : contentRating === 0 ? "All ages" : null;

    return <Modal
        opened={modal === 'story-preview'}
        onClose={onClose}
        withCloseButton={true}
        centered
        size={mobile ? '100vw' : '95vw'}

    >
        <Paper
            shadow="md"
            p="xl"
            radius="md"
            sx={{
                backgroundImage: `url(${image}), linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(0,0,0,1))`,
                backgroundSize: 'cover, 100% 50%',
                backgroundPosition: 'center, center',
                backgroundRepeat: 'no-repeat, no-repeat'
            }}
            className={classes.preview}
            h={mobile ? '70vw' : '65vw'}
        >
            <div>
                <Text className={classes.category} size="xs">
                    {category}
                </Text>
                <Title order={3} className={classes.title}>
                    {title}
                </Title>
                {contentRatingString && <><Space h="md" /><Text className={classes.preview} sx={{ border: '1px solid #fff', padding: '3px', display: 'inline-block' }}>
                    {contentRatingString}
                </Text></>}
            </div>
        </Paper>
        <Paper
            shadow="md"
            p="xl"
            radius="md"
            className={classes.preview}
            h={mobile ? '15vw' : '20vw'}
        >
            <div>
                <Text className={classes.preview}>
                    by {author}
                </Text>
                <Space h="lg" />
                <Text className={classes.preview}>
                    {description}
                </Text>

            </div>
            <Button variant="default" onClick={() => props.onStartGame(props.story)} data-autofocus>
                Play
            </Button>
        </Paper>
    </Modal>
}

interface CarouselProps {
    onStoryClick: (story: Story) => void;
}

export function StoriesCarousel(props: CarouselProps) {

    const [contentRatingString] = useOption<string>('content', 'rating');
    const [stories, setStories] = useState<Story[]>([]);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const context = useAppContext();
    const { state, cancel } = useTTS();

    useEffect(
        () => {
            // stop tts if playing
            if (state?.playing) {
                cancel();
            }

        }, [state, state?.playing, cancel]
    )

    useEffect(
        () => {
            // TODO: prompt for content rating if not set manually. Couldn't get this to work
            // because the content rating is not set when the component is first rendered
            // we need to wait for the content rating to be set before we can prompt the user,
            // but I can't find the right hook to do this.
            // console.log("contentRatingString: %s (v%s)", contentRatingString, contentRatingVersion);
            // if (contentRatingString === "99") {
            //     dispatch(openContentRatingPanel());
            // }

            const maxContentRating = parseInt(contentRatingString) || 0;

            // fetch stories
            const fetchStories = async () => {
                const result = await SystemPromptPlugin.getStories(maxContentRating);
                setStories(result);
            };

            fetchStories();
        }, [contentRatingString]
    )

    // Group stories by category
    const storiesByCategory = useMemo(() => {
        if (!stories || !stories.length) {
            return {};
        }

        return stories.reduce<Record<string, Story[]>>((acc, story) => {
            const category = story.category;
            if (!acc[category]) {
                acc[category] = [];
            }
            acc[category].push(story);
            return acc;
        }, {});
    }, [stories]);

    if (!stories || !stories.length) {
        return null;
    }

    return (
        <>
            {Object.entries(storiesByCategory)
                .sort((a, b) => a[0].localeCompare(b[0])) // Sort categories alphabetically
                .map(([category, categoryStories]) => (
                    <div key={category}>
                        {/* <h2>{category}</h2> */}
                        <Carousel
                            style={{ width: '100%' }}
                            slideSize="440px"
                            slideGap="xl"
                            breakpoints={[
                                { maxWidth: 'xs', slideSize: '75%', slideGap: 'xs' },
                                { maxWidth: 'sm', slideSize: '60%', slideGap: 'sm' },
                                { maxWidth: 'md', slideSize: '55%', slideGap: 'md' },
                                { maxWidth: 'lg', slideSize: '50%', slideGap: 'lg' },
                            ]}
                            align="center"
                            slidesToScroll={1}
                            loop
                        >

                            {categoryStories.map((story) => (
                                <Carousel.Slide key={story.name}>
                                    <Card story={story} onStoryClick={props.onStoryClick} />
                                </Carousel.Slide>
                            ))}
                        </Carousel>
                    </div>
                ))}
        </>
    );
};

