import styled from '@emotion/styled';
import { Button, ActionIcon, Loader, Popover, Tooltip, Slider, Box } from '@mantine/core';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useAppContext } from '../core/context';
import { useTTS } from '../core/tts/use-tts';
import { useAppSelector } from '../store';
import { selectSettingsTab } from '../store/settings-ui';
import QuickSettings from './quick-settings';
import MessageError from './message-error';

const Container = styled.div`
    background: #292933;
    border-top: thin solid #393933;
    padding: 1rem 1rem 0 1rem;

    .inner {
        max-width: 50rem;
        margin: auto;
        text-align: right;
    }

    .settings-button {
        margin: 0.5rem -0.4rem 0.5rem 1rem;
        font-size: 0.7rem;
        color: #999;
    }
`;


function WaveForm({ recording, listening, userSpeaking, ttsPlaying, generating, transcribing }) {
    let status = "deactivated";

    if (recording && listening) {
        if (userSpeaking) {
            status = "speaking";
        } else {
            status = "recording";
        }
    } else if (ttsPlaying) {
        status = "speaking-tts speaking";
    } else if (generating || transcribing) {
        status = "generating";
    }

    const waveStyle = "waveform " + status;

    return (
        <div className={waveStyle}>
            <div className="bar" id="bar1"></div>
            <div className="bar" id="bar2"></div>
            <div className="bar" id="bar3"></div>
            <div className="bar" id="bar4"></div>
            <div className="bar" id="bar5"></div>
        </div>
    )
}
export interface TalkInputProps {
    disabled?: boolean;
}

export default function TalkInput(props: TalkInputProps) {

    const context = useAppContext();
    const { state, speed, setSpeed, key: ttskey, setSourceElement, play } = useTTS();
    const intl = useIntl();

    const tab = useAppSelector(selectSettingsTab);

    const pathname = useLocation().pathname;

    const disabled = context.generating;

    const isLandingPage = pathname === '/';

    const onToggle = useCallback(() => {
        // console.log("context.vad.listening == %s", context.vad.listening);
        if (context.vadRecording) {
            context.onPauseVAD();
        } else {
            context.onStartVAD();
        }
        // console.log("context.vad.listening == %s", context.vad.listening);
    }, [context.vad, context.vadRecording]);

    const onTextMode = useCallback(() => {
        // context.setVADRecording(false);
        // context.vad.pause();
        context.setTalkMode(false);
    }, [context.talkMode, context.vad, context.vadRecording]
    );

    useEffect(() => {
        // This function runs when the component mounts
        console.log("TalkInput mounted");
        // context.onStartVAD(true);

        return () => {
            // This function runs when the component unmounts
            context.onPauseVAD(true);
            console.log("TalkInput unmounted");
        };
    }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

    useEffect(() => {
        // console.log("TalkInput useEffect fired for context.talkMode: %s, context.vadRecording: %s, state: %s", context.talkMode, context.vadRecording, JSON.stringify(state));        
        
        if (context.talkMode) {
            if (!context.vadRecording && (!state || state.ended)) {
                context.onStartVAD();
            } else if (context.vadRecording && state?.playing) {
                context.onPauseVAD();
            }
        }
    }, [context.talkMode, state, state?.ended, state?.playing]);

    const pauseLabel = intl.formatMessage({
        id: 'pause',
        defaultMessage: 'Mute Microphone',
        description: 'Label for the button that pauses speech recognition'
    });

    const listenLabel = intl.formatMessage({
        id: 'listen',
        defaultMessage: 'Unmute Microphone',
        description: 'Label for the button that starts speech recognition'
    });

    const speedLabel = intl.formatMessage({
        id: 'ttsSpeed',
        defaultMessage: 'Speed of played audio',
        description: 'Label for the button to change the speed of the played audio'
    });

    const textLabel = intl.formatMessage({
        id: 'textMode',
        defaultMessage: 'Switch to Text Mode',
        description: 'Label for the button switches to text mode'
    });

    const voiceAnimation = useMemo(() => {
        return (
            <Box style={{ position: 'absolute' }}>
                {/* {context.vadRecording && context.vad.listening && context.vad.userSpeaking && <HighEnergyCube />}
                {context.vadRecording && context.vad.listening && !context.vad.userSpeaking && <LowEnergyCube />}
                {!(context.vadRecording || context.vad.listening) && <DeactivatedCube />} */}
                <WaveForm recording={context.vadRecording} 
                        listening={context.vad.listening} 
                        userSpeaking={context.vad.userSpeaking} 
                        ttsPlaying={state?.playing}
                        generating={context.generating}
                        transcribing={context.transcribing}/>
                {context.speechError !== null || context.vad.errored &&
                    <Popover width={200} position="top" withArrow shadow="md" opened={(context.speechError !== null)}>
                        <Popover.Target>
                            <ActionIcon size="xl" aria-label="Error" onClick={() => context.onStartVAD()}>
                                <i className="fa fa-exclamation-triangle" />
                            </ActionIcon>
                        </Popover.Target>
                        <Popover.Dropdown>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}>
                                <p style={{
                                    fontFamily: `"Work Sans", sans-serif`,
                                    fontSize: '0.9rem',
                                    textAlign: 'center',
                                    marginBottom: '0.5rem',
                                }}>
                                    Sorry, an error occured trying to record audio.
                                    {context.vad.errored && <span>( {context.vad.errored.message} )</span>}
                                </p>
                                <Button variant="light" size="xs" fullWidth onClick={context.onHideSpeechError}>
                                    Close
                                </Button>
                            </div>
                        </Popover.Dropdown>
                    </Popover>}
                {/* {(context.generating || context.transcribing) && <Loader size="lg" />} */}
            </Box>
        );
    }, [context.vadRecording, context.vad.listening, context.vad.userSpeaking, context.generating, context.transcribing, context.speechError, context.vad.errored, state?.playing, context.messageError]);

    const rightSection = useMemo(() => {
        return (
            <Box style={{ marginLeft: 'auto',
                // opacity: '0.8',
                // paddingRight: '0.5rem',
                display: 'flex',
                // justifyContent: 'flex-end',
                // alignItems: 'right',
                // width: '100%',
            }}>                
                <>
                    {/* {context.generating && (<>
                        <Button variant="subtle" size="xs" compact onClick={() => {
                            context.game.cancelReply(context.currentGame.game?.id, context.currentGame.leaf!.id);
                        }}>
                            <FormattedMessage defaultMessage={"Cancel"} description="Label for the button that can be clicked while the AI is generating a response to cancel generation" />
                        </Button>
                    </>)} */}
                    {context.vadRecording &&
                        <Tooltip label={pauseLabel} withArrow arrowSize={7} openDelay={1000}>
                            <ActionIcon size="xl" onClick={onToggle} aria-label={pauseLabel} disabled={props.disabled || disabled}>
                                <i className="fa fa-microphone" />
                            </ActionIcon>
                        </Tooltip>
                    }
                    {!context.vadRecording &&
                        <Tooltip label={listenLabel} withArrow arrowSize={7} openDelay={1000}>
                            <ActionIcon style={{ backgroundColor: 'rgba(52,58,64,0.2)' }} size="xl" onClick={onToggle} aria-label={listenLabel} disabled={props.disabled || disabled}>
                                <i className="fa fa-microphone-slash" />
                            </ActionIcon>
                        </Tooltip>
                    }
                    <Popover width={200} position="top" shadow="md">
                        <Popover.Target>
                            <Tooltip label={speedLabel} withArrow arrowSize={7} openDelay={1000}>
                                <ActionIcon size="xl" aria-label={speedLabel}>
                                    {speed}x
                                </ActionIcon>
                            </Tooltip>
                        </Popover.Target>
                        <Popover.Dropdown>
                            <Slider defaultValue={speed} 
                                    min={0.25} max={2} step={0.25} 
                                    marks={[{ value: speed }]}
                                    onChangeEnd={setSpeed} />
                        </Popover.Dropdown>
                    </Popover>
                    <Tooltip label={textLabel} withArrow arrowSize={7} openDelay={1000}>
                        <ActionIcon size="xl" onClick={onTextMode} aria-label={textLabel}>
                            <i className="fa fa-message" style={{ fontSize: '90%' }} />
                        </ActionIcon>
                    </Tooltip>
                </>
            </Box>
        );
    }, [onTextMode, onToggle, speed, ttskey, context.vadRecording, context.vad.listening, context.transcribing, context.generating, context.vad.userSpeaking, context.speechError]);

    if (isLandingPage) {
        return null;
    }

    if (context.isShare || (!isLandingPage && !context.id)) {
        return null;
    }

    if (!context.talkMode) {
        return null;
    }    

    // const hotkeyHandler = useMemo(() => {
    //     const keys = [
    //         ['Escape', blur, { preventDefault: true }],
    //         ['ctrl+Enter', onSubmit, { preventDefault: true }],

    //     ];
    //     if (submitOnEnter) {
    //         keys.unshift(['Enter', onSubmit, { preventDefault: true }]);
    //     }
    //     const handler = getHotkeyHandler(keys as any);
    //     return handler;
    // }, [onSubmit, blur, submitOnEnter]);

    // if (context.vad.loading) {
    //     return <Loading />
    // }

    // if (context.vad.errored) {
    //     return <Errored errorMessage={context.vad.errored.message}/>
    // }

    return <Container>
        <div className="inner">
            <Box my="xl" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {voiceAnimation}
                {rightSection}
            </Box>
            <QuickSettings key={tab} />
        </div>
    </Container>;
}
