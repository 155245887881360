import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "./core/context";

export function useGameSpotlightProps() {
    const navigate = useNavigate();
    const { game } = useAppContext();
    const intl = useIntl();

    const [version, setVersion] = useState(0);

    useEffect(() => {
        const handleUpdate = () => setVersion(v => v + 1);
        game.on('update', handleUpdate);
        return () => {
            game.off('update', handleUpdate);
        };
    }, [game]);

    const search = useCallback((query) => {
        return game.searchGames(query)
            .map((result) => ({
                ...result,
                onTrigger: () => navigate(`/game/${result.gameID}${result.messageID ? `#msg-${result.messageID}` : ''}`),
            }))
    }, [game, navigate, version]);

    const props = useMemo(() => ({
        shortcut: ['/'],
        overlayColor: '#000000',
        searchPlaceholder: intl.formatMessage({ defaultMessage: 'Search your games' }),
        searchIcon: <i className="fa fa-search" />,
        actions: search,
        filter: (query, items) => items,
    }), [search]);

    return props;
}