import { FormattedMessage } from "react-intl";
import { OptionGroup } from "../core/options/option-group";
import { Story } from "../core/game/types";

export const contentOptions: OptionGroup = {
    id: 'content',
    options: [
        {
            id: 'rating',
            defaultValue: "18",
            displayOnSettingsScreen: "user",
            scope: "user",
            displayAsSeparateSection: true,
            renderProps: (value, options, context) => ({
                hidden: false,
                type: "select",
                label: "Maturity Rating",
                description: <>
                    <p>
                        <FormattedMessage defaultMessage="We'll show you only games with the selected rating." />
                    </p>
                </>,
                options: [
                    { label: "Appropriate for all ages", value: "0" },
                    { label: "7+", value: "7" },
                    { label: "13+", value: "13" },
                    { label: "16+", value: "16" },
                    { label: "18+", value: "18" },
                ],
            }),
        },
    ],
}

export function isAppropriate(story: Story, maxContentRating?: number) {

    return maxContentRating === undefined || 
        maxContentRating >= 18 ||
        (!story?.contentRating) || 
        (story.contentRating <= maxContentRating);
        
}