import { useCallback, useEffect, useState } from "react";
import { backend } from "../backend";
import { GameManager } from "..";
import { Game, Message } from './types';

export interface UseGameResult {
    game: Game | null | undefined;
    gameLoadedAt: number;
    messages: Message[];
    messagesToDisplay: Message[];
    leaf: Message | null | undefined;
}

export function useGame(gameManager: GameManager, id: string | undefined | null, share = false): UseGameResult {
    const [game, setGame] = useState<Game | null | undefined>(null);
    const [_, setVersion] = useState(0); // eslint-disable-line @typescript-eslint/no-unused-vars

    // used to prevent auto-scroll when game is first opened
    const [gameLoadedAt, setLoadedAt] = useState(0);

    const update = useCallback(async () => {
        if (id) {
            if (!share) {
                const c = gameManager.get(id);
                if (c) {
                    setGame(c);
                    setVersion(v => v + 1);
                    return;
                }
            } else {
                const c = await backend.current?.getSharedGame(id);
                if (c) {
                    setGame(c);
                    setVersion(v => v + 1);
                    return;
                }
            }
        }
        setGame(null);
    }, [id, share, gameManager]);

    useEffect(() => {
        if (id) {
            update();
            gameManager.on(id, update);
            setGame(gameManager.get(id));
            setLoadedAt(Date.now());
        } else {
            setGame(null);
            setLoadedAt(0);
        }
        return () => {
            if (id) {
                gameManager.off(id, update);
            }
        };
    }, [id, update, gameManager]);

    const leaf = game?.messages.mostRecentLeaf();

    let messages: Message[] = [];
    let messagesToDisplay: Message[] = [];

    if (leaf) {
        messages = (game?.messages.getMessageChainTo(leaf?.id) || []);
        messagesToDisplay = messages.filter(m => ['user', 'assistant'].includes(m.role)) || [];
    }

    return {
        game,
        gameLoadedAt,
        messages,
        messagesToDisplay,
        leaf,
    };
}