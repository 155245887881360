import { BroadcastChannel } from 'broadcast-channel';
import EventEmitter from 'events';
import { v4 as uuidv4 } from 'uuid';
import { Game, Message, Story, Parameters, UserSubmittedMessage, TranscriptionParameters } from './game/types';
import * as Y from 'yjs';
import { IndexeddbPersistence } from 'y-indexeddb';
import { YGameDoc } from './game/y-game';
import { loadFromPreviousVersion as loadSavedGamesFromPreviousVersion } from './game/game-persistance';
import { Search } from './search';
import { ReplyRequest } from './game/create-reply';
import { createTranscription, createUserTurn } from './game/openai';
import { OptionsManager } from './options';
import { Option } from './options/option';
import { pluginMetadata, ttsPlugins } from './plugins/metadata';
import { pluginRunner } from "./plugins/plugin-runner";
import { createBasicPluginContext } from './plugins/plugin-context';
import { SystemPromptPlugin } from '../plugins/system-prompt';
import TTSPlugin from './tts/tts-plugin';
import message from '../store/message';

export const channel = new BroadcastChannel('games');

export class GameManager extends EventEmitter {
    public doc!: YGameDoc;
    private provider!: IndexeddbPersistence;
    private search!: Search;
    public options!: OptionsManager;
    private username: string | null = "anonymous";

    private activeReplies = new Map<string, ReplyRequest>();
    private changedIDs = new Set<string>();
    public lastReplyID: string | null = null;

    constructor() {
        super();

        this.setMaxListeners(1000);

        console.log('initializing game manager');

        this.doc = this.attachYDoc('anonymous');

        loadSavedGamesFromPreviousVersion(this.doc)
            .then(() => this.emit('update'));
        
        setInterval(() => this.emitChanges(), 100);

        channel.onmessage = message => {
            if (message.type === 'y-update') {
                this.applyYUpdate(message.data);
            }
        };

        (window as any).game = this;
    }

    public login(username: string) {
        if (username && this.username !== username) {
            this.username = username;
            this.attachYDoc(username);
        }
    }

    private attachYDoc(username: string) {
        console.log('attaching y-doc for ' + username);

        // detach current doc
        const doc = this.doc as YGameDoc | undefined;
        const provider = this.provider as IndexeddbPersistence | undefined;
        doc?.removeAllListeners();
        
        const pluginOptionsManager = this.options as OptionsManager | undefined;
        pluginOptionsManager?.destroy();

        // attach new doc
        this.doc = new YGameDoc();
        this.doc.on('update', gameID => this.changedIDs.add(gameID));
        this.doc.root.on('update', (update, origin) => {
            if (!(origin instanceof IndexeddbPersistence) && origin !== 'sync') {
                this.emit('y-update', update);
                channel.postMessage({ type: 'y-update', data: update });
            } else {
                console.log("IDB/sync update");
            }
        });
        this.search = new Search(this);

        this.options = new OptionsManager(this.doc, pluginMetadata);
        this.options.on('update', (...args) => this.emit('plugin-options-update', ...args));

        // connect new doc to persistance, scoped to the current username
        this.provider = new IndexeddbPersistence('games:' + username, this.doc.root);
        this.provider.whenSynced.then(() => {
            this.doc.getGameIDs().map(id => {
                this.emit(id);
                this.search.update(id);
            });
            this.emit('update');
            this.doc.emit('ready');
            this.options.reloadOptions();
        });

        pluginRunner(
            'init',
            pluginID => createBasicPluginContext(pluginID, this.options),
            plugin => plugin.initialize(),
        );

        if (username !== 'anonymous') {
            // import games from the anonymous doc after signing in
            provider?.whenSynced.then(() => {
                if (doc) {
                    Y.applyUpdate(this.doc.root, Y.encodeStateAsUpdate(doc.root));
                    setTimeout(() => provider.clearData(), 10 * 1000);
                }
            });
        }

        return this.doc;
    }

    public applyYUpdate(update: Uint8Array) {
        Y.applyUpdate(this.doc.root, update);
    }

    private emitChanges() {
        const ids = Array.from(this.changedIDs);
        this.changedIDs.clear();
        
        for (const id of ids) {
            this.emit(id);
            this.search.update(id);
        }

        if (ids.length) {
            this.emit('update');
        }
    }

    // sets the voice for the game with gameID if it is specified in requestedParameters
    async setVoice(story: Story, gameID: string) {
        // set voice option for this (new) game

        // jump through hoops to get the tts plugin that is being used for this game
        const ttsPluginID = this.options?.getOption('tts', 'service', gameID);
        const ttsPluginIndex = ttsPlugins.findIndex(p => new p().describe().id === ttsPluginID) || 0;
        const ttsPluginImpl = ttsPlugins[ttsPluginIndex];
        const ttsPlugin = new ttsPluginImpl(createBasicPluginContext(ttsPluginID, this.options)) as TTSPlugin;

        // get its available voices
        const availableVoices = await ttsPlugin.getVoices();

        // get the voice specified in the story
        const requestedVoices = story.voice;

        try {
            if (requestedVoices) {
                // requestedVoices is a map of ttsPluginID -> voiceID
                // so get the voiceID for the ttsPluginID that is being used for this game
                const requestedVoiceID = requestedVoices[ttsPluginID];
    
                if (requestedVoiceID && availableVoices.find(v => v.id === requestedVoiceID)) {
    
                    this.options?.setOption(ttsPluginID, 'voice', requestedVoiceID, gameID);

                    // return successfully if we have set the requested voice 
                    return;
                }
            }
        } catch (error) {
            console.error(`Error setting voice for game ${gameID}: ${error}`);
        }

        // fall back to last set voice 
        let defaultVoiceID = this.options?.getOption(ttsPluginID, 'voice', gameID);

        // if no voice is set, set the first voice in the list
        if (!defaultVoiceID) {

            defaultVoiceID = availableVoices[0].id;
        }

        // set it in any case so it is available at the game level
        this.options?.setOption(ttsPluginID, 'voice', defaultVoiceID, gameID);

    }

    public async sendMessage(userSubmittedMessage: UserSubmittedMessage) {
        const gameID = userSubmittedMessage.gameID;
        const game = this.doc.getYGame(gameID);

        if (!game) {
            throw new Error('Game not found');
        }

        if (game.story) {
            // previsouly used game so get its story and propagate in requests
            userSubmittedMessage.requestedParameters.storyName = game.story.name;
        }

        const message: Message = {
            id: uuidv4(),
            parentID: userSubmittedMessage.parentID,
            gameID: userSubmittedMessage.gameID,
            timestamp: Date.now(),
            role: 'user',
            content: userSubmittedMessage.content,
            done: true,
        };

        this.doc.addMessage(message);

        const messages: Message[] = this.doc.getMessagesPrecedingMessage(message.gameID, message.id);
        messages.push(message);

        const result = await this.getReply(gameID, messages, userSubmittedMessage.requestedParameters);

        if (result && result.action) {
            // delete last message
            this.doc.deleteMessage(message);
        }

        return result;
    }

    public async regenerate(message: Message, requestedParameters: Parameters) {
        const gameID = message.gameID;
        const game = this.doc.getYGame(gameID);

        if (!game) {
            throw new Error('Game not found');
        }

        if (game.story) {
            // previsouly used game so get its story and propagate in requests
            requestedParameters.storyName = game.story.name;
        }

        if (message && game.story?.type === 's') {
            
            // no need to regenerate for structured games

            // delete all messages after this one
            this.doc.deleteReplies(gameID, message.id);

            // set current message as the last one
            this.lastReplyID = message.id;

            message.timestamp = Date.now();

            this.doc.addMessage(message);

            game.onMessageDone(message.id);

        } else {

            const messages = this.doc.getMessagesPrecedingMessage(gameID, message.id);
            
            await this.getReply(gameID, messages, requestedParameters);
        }
    }

    public async initiate(gameID: string, requestedParameters: Parameters) {
        const game = this.doc.getYGame(gameID);

        if (!game) {
            throw new Error('Game not found');
        }

        if (requestedParameters.storyName) {
            // set story for this game
            const story = await SystemPromptPlugin.getStory(requestedParameters.storyName);
            if (story) {
                game.story = story;
                console.log(`GameManager set story=${game.story.name} for game with id ${gameID}`);

                // // optionally set the voice for this game
                this.setVoice(story, gameID);

            } else {
                throw new Error(`Story not found: ${requestedParameters.storyName}`);
            }
        } else {
            throw new Error(`Story name not specified`);
        }

        // const message: Message = {
        //     id: uuidv4(),
        //     parentID: parentID,
        //     gameID: gameID,
        //     timestamp: Date.now(),
        //     role: 'system',
        //     content: '',
        //     done: true,
        // };

        // this.doc.addMessage(message);

        // const messages: Message[] = this.doc.getMessagesPrecedingMessage(message.gameID, message.id);
        // messages.push(message);

        const messages: Message[] = [];

        await this.getReplyForIDs(gameID, undefined, messages, requestedParameters);
    }

    private async getReply(gameID: string, messages: Message[], requestedParameters: Parameters) {
        let parentID : string | undefined = undefined;

        if (messages && messages.length > 0) {
            const latestMessage = messages[messages.length - 1];
            parentID = latestMessage.id;
        }
        
        return await this.getReplyForIDs(gameID, parentID, messages, requestedParameters);
    }

    private async getReplyForIDs(gameID:string, parentID: string | undefined, messages: Message[], requestedParameters: Parameters) {
        const yGame = this.doc.getYGame(gameID);

        if (!yGame) {
            throw new Error('Game not found');
        }

        const game = this.get(gameID);

        if (game.story?.type === 's') {
            
            const message = await createUserTurn(game, yGame, parentID, messages, requestedParameters, this.options);
    
            if (message && !message.action) {
                // regular message
                this.lastReplyID = message.id;

                this.doc.addMessage(message);

                yGame.onMessageDone(message.id);
            }

            // return the message in any case as it may contain an action
            return message;

        } else {

            const message: Message = {
                id: uuidv4(),
                parentID,
                gameID,
                timestamp: Date.now(),
                role: 'assistant',
                model: requestedParameters.model,
                content: '',
            };

            this.lastReplyID = message.id;

            this.doc.addMessage(message);

            const request = new ReplyRequest(game, yGame, messages, message.id, requestedParameters, this.options);
            request.on('done', () => this.activeReplies.delete(message.id));
            request.execute();

            this.activeReplies.set(message.id, request);

            return message;
        }

    }

    public async transcribe(speechFile: File, requestedParameters: TranscriptionParameters) {
        return createTranscription(speechFile, requestedParameters, this.lastReplyID);
    }

    public cancelReply(gameID: string | undefined, id: string) {
        this.activeReplies.get(id)?.onCancel();
        this.activeReplies.delete(id);
    }

    public async createGame(id?: string): Promise<string> {
        return this.doc.createYGame(id);
    }

    public get(id: string): Game {
        return this.doc.getGame(id);
    }

    public has(id: string) {
        return this.doc.has(id);
    }

    public all(): Game[] {
        return this.doc.getGameIDs().map(id => this.get(id));
    }

    public deleteGame(id: string, broadcast = true) {
        this.doc.delete(id);
        this.search.delete(id);
    }

    public searchGames(query: string) {
        return this.search.query(query);
    }

    public getPluginOptions(gameID?: string) {
        const pluginOptions: Record<string, Record<string, any>> = {};

        for (const description of pluginMetadata) {
            pluginOptions[description.id] = this.options.getAllOptions(description.id, gameID);
        }

        return pluginOptions;
    }

    public setPluginOption(pluginID: string, optionID: string, value: any, gameID?: string) {
        this.options.setOption(pluginID, optionID, value, gameID);
    }

    public resetPluginOptions(pluginID: string, gameID?: string | null) {
        this.options.resetOptions(pluginID, gameID);
    }

    public getQuickSettings(): Array<{ groupID: string, option: Option }> {
        const options = this.options.getAllOptions('quick-settings');
        return Object.keys(options)
            .filter(key => options[key])
            .map(key => {
                const groupID = key.split('--')[0];
                const optionID = key.split('--')[1];
                return {
                    groupID,
                    option: this.options.findOption(groupID, optionID)!,
                };
            })
            .filter(o => !!o.option);
    }
}