import React, { Suspense, useCallback } from 'react';
import styled from '@emotion/styled';
import slugify from 'slugify';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '@mantine/core';

import { useAppContext } from '../../core/context';
import { backend } from '../../core/backend';
import { Page } from '../page';
import { useOption } from '../../core/options/use-option';
import { Box, Image } from '@mantine/core';
import { Story } from '../../core/game/types';
import { Choices } from '../choices';

const Message = React.lazy(() => import(/* webpackPreload: true */ '../message'));

const Messages = styled.div`
    @media (min-height: 30em) {
        max-height: 100%;
        flex-grow: 1;
        overflow-y: scroll;
    }
    display: flex;
    flex-direction: column;
`;

const EmptyMessage = styled.div`
    flex-grow: 1;
    padding-bottom: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Work Sans", sans-serif;
    line-height: 1.7;
    gap: 1rem;
    min-height: 10rem;
`;

export function coverImage(story: Story | null | undefined) {
    if (!story) {
        return null;
    }

    // if cover provided, use it
    if (story.cover) {
        return story.cover;
    }

    // otherwise return default
    return null; //`/stories/${encodeURIComponent(story.name)}.webp`;
}


export default function GamePage(props: any) {
    const { id } = useParams();
    const context = useAppContext();

    const [autoScrollWhenOpeningGame] = useOption('auto-scroll', 'auto-scroll-when-opening-game')
    const [autoScrollWhileGenerating] = useOption('auto-scroll', 'auto-scroll-while-generating');

    useEffect(() => {
        if (props.share || !context.currentGame.gameLoadedAt) {
            return;
        }

        const shouldScroll = autoScrollWhenOpeningGame || (Date.now() - context.currentGame.gameLoadedAt) > 5000;

        if (!shouldScroll) {
            return;
        }

        const container = document.querySelector('#messages') as HTMLElement;
        const messages = document.querySelectorAll('#messages .message');

        if (messages.length) {
            const latest = messages[messages.length - 1] as HTMLElement;
            const offset = Math.max(0, latest.offsetTop - 100);
            setTimeout(() => {
                container?.scrollTo({ top: offset, behavior: 'smooth' });
            }, 100);
        }
    }, [context.talkMode, context.currentGame?.gameLoadedAt, context.currentGame?.messagesToDisplay.length, props.share, autoScrollWhenOpeningGame]);

    const autoScroll = useCallback(() => {
        if (context.generating && autoScrollWhileGenerating) {
            const container = document.querySelector('#messages') as HTMLElement;
            container?.scrollTo({ top: 999999, behavior: 'smooth' });
            container?.parentElement?.scrollTo({ top: 999999, behavior: 'smooth' });
        }
    }, [context.generating, autoScrollWhileGenerating]);
    useEffect(() => {
        const timer = setInterval(() => autoScroll(), 1000);
        return () => {
            clearInterval(timer);
        };
    }, [autoScroll]);

    const messagesToDisplay = context.currentGame.messagesToDisplay;

    const shouldShowGame = id && context.currentGame.game && !!messagesToDisplay.length;

    const story = context.currentGame.game?.story;
    const storyImage = coverImage(story);
    const storyTitle = story?.title || '';
    const latestMessage = messagesToDisplay[messagesToDisplay.length - 1];
    const choices = latestMessage?.choices;

    return <Page id={id || 'landing'}
        headerProps={{
            share: context.isShare,
            canShare: false, //messagesToDisplay.length > 1,
            title: (id && messagesToDisplay.length) ? storyTitle : null,
            onShare: async () => {
                if (context.currentGame.game) {
                    const id = await backend.current?.shareGame(context.currentGame.game);
                    if (id) {
                        const slug = context.currentGame.game.title
                            ? '/' + slugify(context.currentGame.game.title.toLocaleLowerCase())
                            : '';
                        const url = window.location.origin + '/s/' + id + slug;
                        navigator.share?.({
                            title: context.currentGame.game.title || undefined,
                            url,
                        });
                    }
                }
            },
        }}>
        {context.talkMode && <>
            <Box sx={{ maxWidth: '90%', overflow: 'clip', maxHeight: '100%'}} mx="auto">
                <Image src={storyImage} radius="sm" fit="fill" />
            </Box>
        </>}
        <Suspense fallback={<Messages id="messages">
            <EmptyMessage>
                <Loader variant="dots" />
            </EmptyMessage>
        </Messages>}>
            <Messages id="messages" style={context.talkMode ? { overflow: 'hidden' } : {}}>
                {shouldShowGame && (
                    <div style={{ paddingBottom: '4.5rem' }}>
                        {messagesToDisplay.map((message) => (
                            <Message key={id + ":" + message.id}
                                message={message}
                                share={props.share}
                                last={context.currentGame.game!.messages.leafs.some(n => n.id === message.id)} />
                        ))}
                    </div>
                )}
                {!shouldShowGame && <EmptyMessage>
                    <Loader variant="dots" />
                </EmptyMessage>}
            </Messages>
        </Suspense>
        {choices && <Choices choices={choices} />}
    </Page>;
}
