import styled from '@emotion/styled';
import { Button } from '@mantine/core';
import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../store';
import { Page } from '../page';
import { useOption } from '../../core/options/use-option';
import { isProxySupported } from '../../core/game/openai';
import { useAppContext } from '../../core/context';
//for login button
import { backend } from '../../core/backend';
import { closeModals, openLoginModal, openSignupModal, openStoryPreviewModal } from '../../store/ui';
import MessageError from '../message-error';
import { StoriesCarousel, StoryPreviewModal } from '../stories-carousel';
import { Story } from '../../core/game/types';
///

const Container = styled.div`
    flex-grow: 1;
    padding-bottom: 5vh;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: ;
    font-family: "Work Sans", sans-serif;
    line-height: 1.7;
    gap: 1rem;
    overflow-y: auto ; /* Enable vertical scrolling */
`;

export default function LandingPage(props: any) {
  const [openAIApiKey] = useOption<string>('openai', 'apiKey');
  const [clickedStory, setClickedStory] = useState<Story | null>(null);

  const dispatch = useAppDispatch();
  const context = useAppContext();

  const allowStart = openAIApiKey || isProxySupported();

  //for signIn
  const signIn = useCallback(() => {
    if ((window as any).AUTH_PROVIDER !== 'local') {
      backend.current?.signIn();
    } else {
      dispatch(openLoginModal());
    }
  }, [dispatch])


  const onStoryClick = useCallback((story: Story) => {
    console.log("Story clicked: %s ...", story.name);

    setClickedStory(story);

    // show a preview of the story as a mantine modal dialog
    dispatch(openStoryPreviewModal());


  }, [dispatch]);


  const onStartGame = useCallback(async (story: Story | null) => {
    if (!story) {
      return;
    }

    console.log("Game starting: %s ...", story.name);

    try {
      dispatch(closeModals());

      if (story) {
        // start a new game
        const id = await context.onNewGame(story);
      } else {
        console.error("Failed to start game: %s", JSON.stringify(story));
      }
    } catch (error) {
      console.error("Failed to start game: %s", error);
    }
  }, [context]);

  return <Page id={'landing'} showSubHeader={true}>
    <Container>
      <MessageError message={context.messageError} />
      {!allowStart && (

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <FormattedMessage defaultMessage={'Welcome friend. I\'m Falkor, your guide.'} />
          <Button size="xl" variant="light" compact onClick={(signIn)}>
            <FormattedMessage defaultMessage={'Sign in to get started'} />
          </Button>
        </div>

      )}

      {allowStart && (<>
        <StoriesCarousel onStoryClick={onStoryClick} />
        <StoryPreviewModal story={clickedStory} onStartGame={onStartGame} />
        {/* <p>
          <FormattedMessage defaultMessage={'Choose a game you\'d like to play.'}
            description="Message below game carousel to select a new game." />
        </p> */}
      </>
      )}
    </Container>
  </Page>;
}
