import styled from '@emotion/styled';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { useAppContext } from '../../core/context';
import { useAppDispatch } from '../../store';
import { toggleSidebar } from '../../store/sidebar';
import { ActionIcon, Avatar, Button, Col, Loader, Menu, Paper, Textarea, Tooltip, Space } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { backend } from '../../core/backend';
import { useOption } from '../../core/options/use-option';
import { isAppropriate } from '../../global-options/content';

const Container = styled.div`
    margin: calc(1.618rem - 1rem);
    margin-top: -0.218rem;
`;

const Empty = styled.p`
    text-align: center;
    font-size: 0.8rem;
    padding: 2rem;
`;

const GameList = styled.div``;

const GameListItemLink = styled(Link)`
    display: block;
    position: relative;
    padding: 0.4rem 1rem;
    margin: 0.218rem 0;
    line-height: 1.7;
    text-decoration: none;
    border-radius: 0.25rem;

    &:hover, &:focus, &:active {
        background: rgba(0, 0, 0, 0.1);
    }

    &.selected {
        background: #2b3d54;
    }

    strong {
        display: block;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.6;
        padding-right: 1rem;
        color: white;
    }

    p {
        font-size: 0.8rem;
        font-weight: 200;
        opacity: 0.8;
    }

    .mantine-ActionIcon-root {
        position: absolute;
        right: 0.0rem;
        top: 50%;
        margin-top: -22px;
        opacity: 0;
    }

    &:hover {
        .mantine-ActionIcon-root {
            opacity: 1;
        }
    }
`;

function GameListItem(props: { game: any, onClick: any, selected: boolean }) {
    const c = props.game;
    const context = useAppContext();
    const modals = useModals();
    const navigate = useNavigate();

    const onDelete = useCallback((e?: React.MouseEvent) => {
        e?.preventDefault();
        e?.stopPropagation();

        modals.openConfirmModal({
            title: "Are you sure you want to delete this game?",
            children: <p style={{ lineHeight: 1.7 }}>The game "{c.title}" will be permanently deleted. This cannot be undone.</p>,
            labels: {
                confirm: "Delete permanently",
                cancel: "Cancel",
            },
            confirmProps: {
                color: 'red',
            },
            onConfirm: async () => {
                try {
                    await backend.current?.deleteGame(c.gameID);
                    context.game.deleteGame(c.gameID);
                    navigate('/');
                } catch (e) {
                    console.error(e);
                    modals.openConfirmModal({
                        title: "Something went wrong",
                        children: <p style={{ lineHeight: 1.7 }}>The game "{c.title}" could not be deleted.</p>,
                        labels: {
                            confirm: "Try again",
                            cancel: "Cancel",
                        },
                        onConfirm: () => onDelete(),
                    });
                }
            },
        });
    }, [c.gameID, c.title]);

    const onRename = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        // Display a modal with a TextInput
        modals.openModal({
            title: "Rename game",
            children: <div>
                <Textarea
                    id="game-title"
                    defaultValue={c.title}
                    maxLength={500}
                    autosize
                    required />
                <Button
                    fullWidth
                    variant="light"
                    style={{ marginTop: '1rem' }}
                    onClick={() => {
                        const title = document.querySelector<HTMLInputElement>('#game-title')?.value?.trim();
                        const ygame = context.game.doc.getYGame(c.gameID);
                        if (ygame && title && title !== ygame?.title) {
                            ygame.title = title;
                        }
                        modals.closeAll();
                    }}
                >
                    Save changes
                </Button>
            </div>,
        });
    }, [c.gameID, c.title]);

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setMenuOpen(open => !open);
    }, []);

    if (!c.story) {
        return null;
    }
    
    const storyFirstLetter = c.story.title ? c.story.title.charAt(0).toUpperCase() : 'X';
    
    // format game last played as a string of the format
    // 23 minutes ago, 1 hour ago, 2 days ago, etc.
    const gameLastPlayed = new Date(c.lastPlayed);
    const now = new Date();
    const timeDiff = now.getTime() - gameLastPlayed.getTime();
    let timeDiffStr = '';
    if (timeDiff < 1000 * 60) {
        timeDiffStr = 'just now';
    } else if (timeDiff < 1000 * 60 * 60) {
        const minutes = Math.floor(timeDiff / (1000 * 60));
        timeDiffStr = minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
    } else if (timeDiff < 1000 * 60 * 60 * 24) {
        const hours = Math.floor(timeDiff / (1000 * 60 * 60));
        timeDiffStr = hours === 1 ? '1 hour ago' : `${hours} hours ago`;
    } else if (timeDiff < 1000 * 60 * 60 * 24 * 7) {
        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        timeDiffStr = days === 1 ? '1 day ago' : `${days} days ago`;
    } else {
        timeDiffStr = gameLastPlayed.toLocaleDateString();
    }

    return (
        <GameListItemLink to={'/game/' + c.gameID}
            onClick={props.onClick}
            data-game-id={c.gameID}
            className={props.selected ? 'selected' : ''}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip multiline
                    label={c.story.title + ": " + (c.title || <FormattedMessage defaultMessage={"Untitled"} description="default title for untitled game sessions" />)}>
                    <Avatar
                        src={c.story.cover}
                        alt={c.title || <FormattedMessage defaultMessage={"Untitled"} description="default title for untitled game sessions" />}
                        size="md"
                        color="red"
                    >{storyFirstLetter}
                    </Avatar>
                </Tooltip>
                <Space w="md" />
                <strong>{timeDiffStr}</strong>
            </div>
            <Menu opened={menuOpen}
                closeOnClickOutside={true}
                closeOnEscape={true}
                onClose={() => setMenuOpen(false)}>
                <Menu.Target>
                    <ActionIcon size="xl" onClick={toggleMenu}>
                        <i className="fas fa-ellipsis" />
                    </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                    <Menu.Item onClick={onRename} icon={<i className="fa fa-edit" />}>
                        <FormattedMessage defaultMessage={"Rename this game"} />
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item onClick={onDelete} color="red" icon={<i className="fa fa-trash" />}>
                        <FormattedMessage defaultMessage={"Delete this game"} />
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
        </GameListItemLink>
    );
}

export default function RecentGames(props: any) {
    const context = useAppContext();
    const dispatch = useAppDispatch();

    const currentGameID = context.currentGame.game?.id;
    const allRecentGames = context.game.searchGames('');

    // filter games whose story.contentRating is less than or equal to the user's content rating
    const [contentRatingString] = useOption<string>('content', 'rating');
    const maxContentRating = parseInt(contentRatingString) || 0;

    const recentGames = useMemo(() => {
        if (!allRecentGames || allRecentGames.length === 0) {
            return [];
        }

        return allRecentGames.filter(game => isAppropriate(game.story, maxContentRating));
    }, [allRecentGames, maxContentRating]);

    const onClick = useCallback((e: React.MouseEvent) => {
        if (e.currentTarget.closest('button')) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        if (window.matchMedia('(max-width: 40em)').matches) {
            dispatch(toggleSidebar());
        }
    }, [dispatch]);

    useEffect(() => {
        if (currentGameID) {
            const el = document.querySelector(`[data-game-id="${currentGameID}"]`);
            if (el) {
                el.scrollIntoView();
            }
        }
    }, [currentGameID]);

    const synced = !backend.current || backend.current?.isSynced();

    return (
        <Container>
            {recentGames.length > 0 && <GameList>
                {recentGames.map(c => (
                    <GameListItem key={c.gameID} game={c} onClick={onClick} selected={c.gameID === currentGameID} />
                ))}
            </GameList>}
            {recentGames.length === 0 && !synced && <Empty>
                <Loader size="sm" variant="dots" />
            </Empty>}
            {recentGames.length === 0 && synced && <Empty>
                <FormattedMessage defaultMessage={"No games yet."} description="Message shown on the Game History screen for new users who haven't started their first game session" />
            </Empty>}
        </Container>
    );
}