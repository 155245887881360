import React, { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { v4 as uuidv4 } from 'uuid';
import { IntlShape, useIntl } from "react-intl";
import { Backend, User } from "./backend";
import { GameManager } from "./";
import { useAppDispatch } from "../store";
import { openOpenAIApiKeyPanel } from "../store/settings-ui";
import { Message, Parameters, TranscriptionParameters, Story } from "./game/types";
import { useGame, UseGameResult } from "./game/use-game";
import { TTSContextProvider } from "./tts/use-tts";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isProxySupported } from "./game/openai";
import { resetAudioContext } from "./tts/audio-file-player";
import { useMicVAD, utils } from "@ricky0123/vad-react"
import * as lamejs from 'lamejs';
import { screenWakeLock, screenWakeUnlock } from './utils';
import { removeFromAutoplayed } from "../components/tts-button";
import { setMessage } from "../store/message";

// Audio constant settings
const promptVolume = 0.5;   // Volume for the prompt sound
const themeVolume = 0.5;    // Volume for the theme Music
const fadeoutSeconds = 5;   // Fade-out duration in seconds for the theme Music

export interface Context {
    authenticated: boolean;
    sessionExpired: boolean;
    game: GameManager;
    user: User | null;
    intl: IntlShape;
    id: string | undefined | null;
    currentGame: UseGameResult;
    isHome: boolean;
    isShare: boolean;
    generating: boolean;
    vad: any;
    talkMode: boolean;
    setTalkMode: (talkMode: boolean) => void;
    vadRecording: boolean;
    setVADRecording: (vadRecording: boolean) => void;
    transcribing: boolean;
    setTranscribing: (transcribing: boolean) => void;
    speechError: string | null;
    messageError: string | null;
    setMessageError: (message: string | null) => void;
    onHideSpeechError: () => void;
    onStartVAD: (startTTS?: boolean) => void;
    onPauseVAD: (stopTTS?: boolean) => void;
    onNewGame: (story?: Story) => Promise<string | false>;
    onNewMessage: (message?: string) => Promise<string | false>;
    onTranscribe: (audio: Float32Array) => Promise<string | false>;
    regenerateMessage: (message: Message) => Promise<boolean>;
    editMessage: (message: Message, content: string) => Promise<boolean>;
    muteTheme: () => void;
}

const AppContext = React.createContext<Context>({} as any);

const gameManager = new GameManager();
const backend = new Backend(gameManager);

let intl: IntlShape;

export function useCreateAppContext(): Context {
    const { id: _id } = useParams();
    const [nextID, setNextID] = useState(uuidv4());
    const id = _id ?? nextID;

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    intl = useIntl();
    
    const { pathname } = useLocation();
    const isHome = pathname === '/';
    const isShare = pathname.startsWith('/s/');

    const currentGame = useGame(gameManager, id, isShare);
    const [authenticated, setAuthenticated] = useState(backend?.isAuthenticated || false);
    const [wasAuthenticated, setWasAuthenticated] = useState(backend?.isAuthenticated || false);

    // default is talkMode
    const [talkMode, setTalkMode] = useState(true);
    const [vadRecording, setVADRecording] = useState(true);
    const [transcribing, setTranscribing] = useState(false);
    const [speechError, setSpeechError] = useState<string | null>(null);
    const [messageError, setMessageError] = useState<string | null>(null);
    
    useEffect(() => {
        gameManager.on('y-update', update => backend?.receiveYUpdate(update))
        console.log("New AppContext created with id: %s", id);
    }, []);

    useEffect(() => {
        console.log("AppContext id changed to: %s", id);

    }, [id]);

    const updateAuth = useCallback((authenticated: boolean) => {
        setAuthenticated(authenticated);
        if (authenticated && backend.user) {
            gameManager.login(backend.user.email || backend.user.id);
        }
        if (authenticated) {
            setWasAuthenticated(true);
            localStorage.setItem('registered', 'true');
        }
    }, []);

    useEffect(() => {
        updateAuth(backend?.isAuthenticated || false);
        backend?.on('authenticated', updateAuth);
        return () => {
            backend?.off('authenticated', updateAuth)
        };
    }, [updateAuth]);

    // if talkMode then screenWakeLock
    useEffect(() => {
        if (talkMode) {
            screenWakeLock();
        } else {
            screenWakeUnlock();
        }
    }, [talkMode]);

    const positiveSpeechThreshold = gameManager.options.getOption<number>('vad', 'positiveSpeechThreshold');
    const negativeSpeechThreshold = gameManager.options.getOption<number>('vad', 'negativeSpeechThreshold');
    const minSpeechFrames = gameManager.options.getOption<number>('vad', 'minSpeechFrames');

    const vad = useMicVAD({
        startOnLoad: false,
        positiveSpeechThreshold: positiveSpeechThreshold,
        negativeSpeechThreshold: negativeSpeechThreshold,
        minSpeechFrames: minSpeechFrames,
        onSpeechEnd: useCallback(async (audio : Float32Array) => {
            // console.log("User stopped talking")

            // we should have to check if vad.listening is true here,
            // but onSpeechEnd is called even if we call vad.stop()
            // and I can't figure out why
            if (vadRecording) {
                setTranscribing(true);

                // pause the vad so we don't get any more events
                await onPauseVAD();

                // play theme music
                playTheme();

                // // wait until vad is paused
                // while (vadRecording) {
                //     console.log("Waiting for vad to pause (vadRecording=%s)...", vadRecording);

                //     // wait 100ms
                //     await new Promise(resolve => setTimeout(resolve, 100));
                // }

                // convert audio to WAV
                // const wavBuffer = utils.encodeWAV(audio)

                // send WAV to backend and generate response
                let result : string | boolean = true;
                
                try {
                    result = await onTranscribe(audio);
                } catch (error) {
                    result = false;
                }
                // const wavBuffer = utils.encodeWAV(audio)
                // const base64 = utils.arrayBufferToBase64(wavBuffer)
                // const url = `data:audio/wav;base64,${base64}`
                // setAudioURL(url);

                // if the result was a message, then the VAD is restarted by
                // TalkInput's useEffect hook after the message is played by TTS
                // but if there was no message, then we need to restart the VAD
                if (!result) {
                    await onStartVAD();
                }

                // // wait until vad is started
                // while (!vad.listening) {
                //     console.log("Waiting for vad to start ...")

                //     // wait 100ms
                //     await new Promise(resolve => setTimeout(resolve, 100));
                // }

                setTranscribing(false);
            };
        },[vadRecording, transcribing]),
    });

    const onSpeechError = useCallback((e: any) => {
        console.error('speech recognition error', e);
        setSpeechError(e.message);
        if (vad?.errored) {
            console.error('vad.error', vad.errored.message);
            setSpeechError(e.message + ' ' + vad.errored.message);
        }

        try {
            vad?.pause();
        } catch (e) {
        }

        setVADRecording(false);

    }, [vad, vad.pause, vad.errored, vadRecording]);

    const onHideSpeechError = useCallback(() => setSpeechError(null), []);
    
    const checkMicPermissions = async () => {
        let granted = false;
        let denied = false;

        try {
            const result = await navigator.permissions.query({ name: 'microphone' as any });
            if (result.state == 'granted') {
                granted = true;
            } else if (result.state == 'denied') {
                denied = true;
            }
        } catch (e) { }

        if (!granted && !denied) {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
                stream.getTracks().forEach(track => track.stop());
                granted = true;
            } catch (e) {
                denied = true;
            }
        }

        if (denied) {
            onSpeechError(new Error('speech permission was not granted'));
            return false;
        }

        return true;
    }

    const onStartVAD = useCallback(async (startTTS = false) => {
        const permissionGranted = await checkMicPermissions();

        if (!permissionGranted) {
            return;
        }

        try {
            if (vad.errored || !vadRecording) {
                setVADRecording(true);

                // stop theme music if playing
                muteTheme();

                // play prompt sound
                playPrompt();

                // start VAD
                vad.start();

                console.log("VAD started");
            } else {
                console.log("VAD already started");
            }

            if (vad.errored) {
                console.trace('vad.error', vad.errored.message);
                new Error(vad.errored.message);
            }

        } catch (e) {
            onSpeechError(e);
        }
    }, [vad, vad.start, vadRecording, speechError]);

    const onPauseVAD = useCallback(async (stopTTS = false) => {
        const permissionGranted = await checkMicPermissions();

        if (!permissionGranted) {
            return;
        }

        try {
            if (!vadRecording) {
                console.log("VAD already paused");
            } else {
                // pause VAD
                vad.pause();
                
                setTimeout(() => setVADRecording(false), 500);
                console.log("VAD paused");
            }
        } catch (e) {
            onSpeechError(e);
        }
    }, [vad, vad.pause, vadRecording, speechError]);

    const onMessageError = useCallback(async(error) => {
        if (error && error.message) {
            setMessageError(error.message);
            console.log('Message error set:', error.message);
        } else {
            // clear message error
            setMessageError(null);
            console.log('Message error cleared');
        }
    }, [messageError]);

    // story here is provided only on a new game start
    const onNewGame = useCallback(async (story?: Story, newGameId: string = id) => {
        resetAudioContext();
        
        const openaiApiKey = gameManager.options.getOption<string>('openai', 'apiKey');
    
        if (!openaiApiKey && !isProxySupported()) {
            dispatch(openOpenAIApiKeyPanel());
            return false;
        }
    
        const parameters: Parameters = {
            model: gameManager.options.getOption<string>('parameters', 'model', newGameId),
            temperature: gameManager.options.getOption<number>('parameters', 'temperature', newGameId),
            storyName: story?.name, // if specified on landing page then specify story
            apiKey: openaiApiKey,
        };
    
        if (newGameId === nextID) {
            setNextID(uuidv4());
        }
    
        await onMessageError(null);
        try {
            await gameManager.initiate(newGameId, parameters);

            // navigate to the new game
            if (newGameId) {
                navigate('/game/' + newGameId);
                dispatch(setMessage(''));
            }   
        } catch (error) {
            await onMessageError(error);
            throw error;
        }
        return newGameId;
    }, [dispatch, id, nextID, isShare, gameManager, resetAudioContext, onMessageError]);

    const onQuit = useCallback(async () => {
        // setLoading(true);
        // stop the current game
        context.onPauseVAD();
        // go to the new game page
        navigate(`/`);
        // setLoading(false);
        setTimeout(() => document.querySelector<HTMLTextAreaElement>('#message-input')?.focus(), 100);
    }, [navigate]);

    // story here is provided only on a new game start
    const onNewMessage = useCallback(async (message?: string) => {
        resetAudioContext();
        
        if (isShare) {
            return false;
        }

        if (!message?.trim().length) {
            return false;
        }

        // const openaiApiKey = store.getState().apiKeys.openAIApiKey;
        const openaiApiKey = gameManager.options.getOption<string>('openai', 'apiKey');

        if (!openaiApiKey && !isProxySupported()) {
            dispatch(openOpenAIApiKeyPanel());
            return false;
        }

        const parameters: Parameters = {
            model: gameManager.options.getOption<string>('parameters', 'model', id),
            temperature: gameManager.options.getOption<number>('parameters', 'temperature', id),
        };

        if (id === nextID) {
            setNextID(uuidv4());

            const autoPlay = gameManager.options.getOption<boolean>('tts', 'autoplay');

            if (autoPlay) {
                const ttsService = gameManager.options.getOption<string>('tts', 'service');
                if (ttsService === 'web-speech') {
                    const utterance = new SpeechSynthesisUtterance('Generating');
                    utterance.volume = 0;
                    speechSynthesis.speak(utterance);
                }
            }
        }

        // if (gameManager.has(id)) {
            // gameManager.sendMessage({
            //     gameID: id,
            //     content: message.trim(),
            //     requestedParameters: {
            //         ...parameters,
            //         apiKey: openaiApiKey,
            //     },
            //     parentID: currentGame.leaf?.id,
            // });
        // } else {
        //     await gameManager.createGame(id);

        await onMessageError(null);
        try {
            const result = await gameManager.sendMessage({
                    gameID: id,
                    content: message.trim(),
                    requestedParameters: {
                        ...parameters,
                        apiKey: openaiApiKey,
                    },
                    parentID: currentGame.leaf?.id,
                });

            if (result && result.action) {
                // Pause VAD
                await onPauseVAD();

                // action message
                if (result.action === 'Start over') {
                    const story = currentGame.game?.story;
                    if (story) {
                        // create new game
                        const newGameId = await onNewGame(story, nextID);

                        // update the id on the context
                        if (newGameId) {
                            navigate('/game/' + newGameId);
                            dispatch(setMessage(''));
                        }                        
                    }
                } else if (result.action === 'Back to last choice') {
                    const messages = context.currentGame.messages;
    
                    if (messages && messages.length > 1) {
                        // filter assistant messages
                        const assistantMessages = messages.filter(m => m.role === 'assistant');
    
                        if (assistantMessages && assistantMessages.length > 0) {
                            // get last message by the assistant
                            const lastMessage = assistantMessages[assistantMessages.length - 2];

                            // remove all messages starting with lastMessage from the autoplayed list
                            const lastMessageIndex = messages.indexOf(lastMessage);
                            for (let i = lastMessageIndex; i < messages.length; i++) {
                                removeFromAutoplayed(messages[i].id);
                            }

                            // Regenerate last message
                            await regenerateMessage(lastMessage);

                            // Start VAD
                            await onStartVAD();
                        }
                    }
                } else if (result.action === 'Quit'){
                    await onQuit();
                } else {
                    console.error(`Invalid action: ${result.action}`);
                }
                
            }
        } catch (error) {
            await onMessageError(error);
            throw error;
        }
        // }

        return id;
    }, [dispatch, id, currentGame.leaf, isShare]);

    function convertFloat32Array2Int16(floatbuffer: Float32Array): Int16Array {
        return Int16Array.from(floatbuffer, float => float < 0 ? 0x8000 * float : 0x7FFF * float);
    }

    function convertAudioToMp3(audio: Float32Array): Blob {
        // Convert audio to format expected by lamejs
        const samples = convertFloat32Array2Int16(audio);
        // use lamejs to encode audio to mp3
        const mp3Encoder = new lamejs.Mp3Encoder(1, 16000, 128);

        const mp3Data: Int8Array[] = [];
        const buffer = mp3Encoder.encodeBuffer(samples);
        if (buffer.length > 0) {
            mp3Data.push(buffer);
        }

        // Flush the encoder to get the last few frames
        const finalBuffer = mp3Encoder.flush();
        if (finalBuffer.length > 0) {
            mp3Data.push(finalBuffer);
        }

        // Create a Blob from MP3 data
        const blob = new Blob(mp3Data, { type: 'audio/mp3' });

        // wav size after encoding
        // const bUrl = window.URL.createObjectURL(blob);
        // console.log({ bUrl: bUrl});
        
        return blob;
    }

    const createAudioFile = useCallback(async (audio: Float32Array) => {
        try {
            const whisperFileType = gameManager.options.getOption<string>('speech-recognition', 'speech-file-type', id);

            if (whisperFileType === "wav") {
                // convert audio to WAV
                const wavBuffer = utils.encodeWAV(audio);
                // create blob
                const blob = new Blob([wavBuffer], { type: 'audio/wav' });
                // create and return file
                const spokenFile = new File([blob], 'spoken.wav', { type: 'audio/wav' });
                return spokenFile;

            } else if (whisperFileType === "mp3") {
                // convert audio to MP3
                const blob = convertAudioToMp3(audio);

                // create and return file
                const spokenFile = new File([blob], 'spoken.mp3', { type: 'audio/mpeg' });
                return spokenFile;

            } else {
                throw new Error(`Invalid whisperFileType: ${whisperFileType}`);

            }
        } catch (err) {
            console.error(err)
        }
    }, []);

    const onTranscribe = useCallback(async (audio: Float32Array) => {

        // const openaiApiKey = store.getState().apiKeys.openAIApiKey;
        const openaiApiKey = gameManager.options.getOption<string>('openai', 'apiKey');

        if (!openaiApiKey && !isProxySupported()) {
            dispatch(openOpenAIApiKeyPanel());
            return false;
        }

        const parameters: TranscriptionParameters = {
            model: gameManager.options.getOption<string>('speech-recognition', 'speech-model', id),
            apiKey: openaiApiKey,
        };

        const speechFile = await createAudioFile(audio);

        if (!speechFile) {
            return false;
        }
        
        const message = await gameManager.transcribe(speechFile, parameters);

        console.log('Transcribed message:', message);

        if (!message) {
            return false;
        }

        try {
            await context.onNewMessage(message);
        } catch (error) {
            // console.error(`Invalid transcribed message: ${message}`, error);
            return false; // should try again
        }

        return id;
    }, [dispatch, id, currentGame.leaf, isShare]);

    const regenerateMessage = useCallback(async (message: Message) => {
        resetAudioContext();

        if (isShare) {
            return false;
        }

        // const openaiApiKey = store.getState().apiKeys.openAIApiKey;
        const openaiApiKey = gameManager.options.getOption<string>('openai', 'apiKey');

        if (!openaiApiKey && !isProxySupported()) {
            dispatch(openOpenAIApiKeyPanel());
            return false;
        }

        const parameters: Parameters = {
            model: gameManager.options.getOption<string>('parameters', 'model', id),
            temperature: gameManager.options.getOption<number>('parameters', 'temperature', id),
        };

        await gameManager.regenerate(message, {
            ...parameters,
            apiKey: openaiApiKey,
        });

        return true;
    }, [dispatch, isShare]);

    const editMessage = useCallback(async (message: Message, content: string) => {
        resetAudioContext();
        
        if (isShare) {
            return false;
        }

        if (!content?.trim().length) {
            return false;
        }

        // const openaiApiKey = store.getState().apiKeys.openAIApiKey;
        const openaiApiKey = gameManager.options.getOption<string>('openai', 'apiKey');

        if (!openaiApiKey && !isProxySupported()) {
            dispatch(openOpenAIApiKeyPanel());
            return false;
        }

        const parameters: Parameters = {
            model: gameManager.options.getOption<string>('parameters', 'model', id),
            temperature: gameManager.options.getOption<number>('parameters', 'temperature', id),
        };

        if (id && gameManager.has(id)) {
            await gameManager.sendMessage({
                gameID: id,
                content: content.trim(),
                requestedParameters: {
                    ...parameters,
                    apiKey: openaiApiKey,
                },
                parentID: message.parentID,
            });
        } else {
            const id = await gameManager.createGame();
            await gameManager.sendMessage({
                gameID: id,
                content: content.trim(),
                requestedParameters: {
                    ...parameters,
                    apiKey: openaiApiKey,
                },
                parentID: message.parentID,
            });
        }

        return true;
    }, [dispatch, id, isShare]);

    // theme music and prompt audio
    const [musicAudioContext, setMusicAudioContext] = useState<AudioContext | null>(null);
    const [themeBuffer, setThemeBuffer] = useState<AudioBuffer | null>(null);
    const [promptBuffer, setPromptBuffer] = useState<AudioBuffer | null>(null);
    const [themeSource, setThemeSource] = useState<AudioBufferSourceNode | null>(null);

    useEffect(() => {
        if (!musicAudioContext) {
            setMusicAudioContext(new AudioContext());
        }
    }, []);

    // theme music set to default unless specified in story
    const [themeMusic, setThemeMusic] = useState<string>('/sounds/theme.mp3');
    
    useEffect(() => {
        if (currentGame?.game?.story?.themeMusic) {
            // set theme music state
            setThemeMusic(currentGame?.game.story.themeMusic);
            // invalidate themeBuffer so it will be reloaded
            setThemeBuffer(null);
            console.log("Set theme music to: %s", themeMusic);
        }
    }, [currentGame?.game?.story?.themeMusic]);

    useEffect(() => {
        if (musicAudioContext && !themeBuffer) {
            try{
                fetch(themeMusic)
                    .then(response => response.arrayBuffer())
                    .then(arrayBuffer => musicAudioContext.decodeAudioData(arrayBuffer))
                    .then(audioBuffer => setThemeBuffer(audioBuffer));
            } catch (err) {
                console.error("Failed to fetch themeMusic %s", themeMusic);
                console.error(err);
            }

            playTheme();
            console.log("Playing theme music...")
        }
        if (musicAudioContext && !promptBuffer) {
            try{
                fetch('/sounds/prompt.mp3')
                    .then(response => response.arrayBuffer())
                    .then(arrayBuffer => musicAudioContext.decodeAudioData(arrayBuffer))
                    .then(audioBuffer => setPromptBuffer(audioBuffer));
            } catch (err) {
                console.error("Failed to fetch prompt.mp3");
                console.error(err);
            }
        }
    }, [musicAudioContext, themeBuffer, promptBuffer]);

    const playPrompt = useCallback(() => {
        try {
            if (musicAudioContext && promptBuffer) {
                const source = musicAudioContext.createBufferSource();
                source.buffer = promptBuffer;

                // Create a GainNode
                const gainNode = musicAudioContext.createGain();
                // Set the volume to promptVolume
                gainNode.gain.value = promptVolume;

                // Connect the source to the GainNode, and the GainNode to the destination
                source.connect(gainNode);
                gainNode.connect(musicAudioContext.destination);

                source.start();
            }
        } catch (error) {
            console.error('Failed to play prompt:', error);
        }
    }, [musicAudioContext, promptBuffer, promptVolume]);

    const themeGainNode = useRef<GainNode | null>(null); // Add this line to keep a reference to the GainNode

    const playTheme = () => {
        try {
            if (musicAudioContext && themeBuffer) {
                const source = musicAudioContext.createBufferSource();
                source.buffer = themeBuffer;

                // Create a GainNode
                const gainNode = musicAudioContext.createGain();
                // Set the volume
                gainNode.gain.value = themeVolume;

                // Connect the source to the GainNode, and the GainNode to the destination
                source.connect(gainNode);
                gainNode.connect(musicAudioContext.destination);

                source.start();
                setThemeSource(source); // Save the source to be able to stop it later

                themeGainNode.current = gainNode; // Save the GainNode to use it later
            }
        } catch (error) {
            console.error('Failed to play theme:', error);
        }
    };

    const muteTheme = () => {
        try {
            if (themeSource && musicAudioContext && themeGainNode.current) {
                // Set the initial volume to the current volume
                themeGainNode.current.gain.setValueAtTime(themeVolume, musicAudioContext.currentTime);
                // Fade out to 0 over fadeoutSeconds
                themeGainNode.current.gain.exponentialRampToValueAtTime(0.001, musicAudioContext.currentTime + fadeoutSeconds);

                // Stop the audio after fadeoutSeconds
                themeSource.stop(musicAudioContext.currentTime + fadeoutSeconds);
                setThemeSource(null); // Clear the source after stopping it

                themeGainNode.current = null; // Clear the GainNode after stopping the audio
            }
        } catch (error) {
            console.error('Failed to mute theme:', error);
        }
    };

    const generating = currentGame?.messagesToDisplay?.length > 0
        ? !currentGame.messagesToDisplay[currentGame.messagesToDisplay.length - 1].done
        : false;

    const context = useMemo<Context>(() => ({
        authenticated,
        sessionExpired: !authenticated && wasAuthenticated,
        id,
        user: backend.user,
        intl,
        game: gameManager,
        currentGame,
        isHome,
        isShare,
        generating,
        vad,
        talkMode,
        setTalkMode,
        vadRecording,
        setVADRecording,
        transcribing,
        setTranscribing,
        speechError,
        messageError,
        setMessageError,
        onHideSpeechError,
        onStartVAD,
        onPauseVAD,
        onNewGame,
        onNewMessage,
        onTranscribe,
        regenerateMessage,
        editMessage,
        muteTheme,
    }), [authenticated, wasAuthenticated, generating, onNewMessage, regenerateMessage, editMessage, currentGame, id, isHome, isShare, intl]);

    return context;
}

export function useAppContext() {
    return React.useContext(AppContext);
}

export function AppContextProvider(props: { children: React.ReactNode }) {
    const context = useCreateAppContext();
    return <AppContext.Provider value={context}>
        <TTSContextProvider>
            {props.children}
        </TTSContextProvider>
    </AppContext.Provider>;
}