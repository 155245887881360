import { FormattedMessage } from "react-intl";
import Plugin from "../core/plugins";
import { PluginDescription } from "../core/plugins/plugin-description";
import { Message, OpenAIMessage, Parameters, Story } from "../core/game/types";
import { isAppropriate } from "../global-options/content";

export const defaultSystemPrompt = "{{ story }}";

export interface SystemPromptPluginOptions {
    systemPrompt: string;
}

export class SystemPromptPlugin extends Plugin<SystemPromptPluginOptions> {
    describe(): PluginDescription {
        return {
            id: "system-prompt",
            name: "System Prompt",
            options: [
                // {
                //     id: "systemPrompt",
                //     defaultValue: defaultSystemPrompt,
                //     displayOnSettingsScreen: "game",
                //     resettable: true,
                //     scope: "game",
                //     renderProps: {
                //         type: "textarea",
                //         description: <p>
                //             <FormattedMessage defaultMessage={"The System Prompt is an invisible message inserted at the start of the game and can be used to give ChatGPT information about itself and general guidelines for how it should respond. The <code>'{{ story }}'</code> tag is automatically replaced by the current story."}
                //                 values={{ code: v => <code>{v}</code> }} />
                //         </p>,
                //     },
                //     displayInQuickSettings: {
                //         name: "System Prompt",
                //         displayByDefault: true,
                //         label: "Customize system prompt",
                //     },
                // },
            ],
        };
    }

    static stories: Map<string, Story> = new Map();

    private static endpoint = '/gameapi/stories';

    public static async getStoriesMap() {
        if (SystemPromptPlugin.stories?.size > 0) {
            return SystemPromptPlugin.stories;
        }

        // fetch stories once
        const response = await fetch(this.endpoint, {
            headers: { 'Content-Type': 'application/json' },
        });

        const stories = await response.json();
        if (stories?.length) {
            // for each story in stories add to the map
            for (const story of stories) {
                SystemPromptPlugin.stories.set(story.name, story);
            }

            console.log("Populated stories");
            console.log("Stories: %s", JSON.stringify(SystemPromptPlugin.stories));
        }

        return SystemPromptPlugin.stories;
    }

    /**
     * Fetches and returns the available stories from the server.
     * This function stores the list of voices in a static variable, which is used elsewhere.
     * @returns {Promise<Story[]>} A promise that resolves to an array of Voice objects.
     */
    public static async getStories(maxContentRating?: number): Promise<Story[]> {

        const stories = await SystemPromptPlugin.getStoriesMap();

        const availableStories = Array.from(stories.values());

        // filter available stories by content rating
        return availableStories.filter(story => isAppropriate(story, maxContentRating));
    }
    
    /**
     * Returns a story by name.
     * @returns {Story | undefined}
     */
    public static async getStory(storyName: string): Promise<Story> {

        const stories = await SystemPromptPlugin.getStoriesMap();

        const story = stories.get(storyName);

        if (!story) {
            throw new Error(`Story not found: ${storyName}`);
        }

        if (!isAppropriate(story)) {
            throw new Error(`Story not appropriate: ${storyName}`);
        }

        return story;
    }

    async preprocessModelInput(messages: Message[], parameters: Parameters): Promise<{ messages: Message[]; parameters: Parameters; }> {
        const output = [
            {
                role: 'system',
                content: (this.options?.systemPrompt || defaultSystemPrompt)
                    .replace('{{ datetime }}', new Date().toLocaleString()),
            },
            ...messages,
        ];

        return {
            messages: output,
            parameters,
        };
    }
}