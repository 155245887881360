import { wrap } from "comlink";
import { Message } from "../game/types";
import type { GameHistoryTrimmerOptions } from "./game-history-trimmer";
// @ts-ignore
import tokenizer from "./worker?worker&url";

const worker = wrap<typeof import("./worker")>(
  new Worker(new URL(tokenizer, import.meta.url), { type: "module" })
);

export async function runGameTrimmer(
  messages: Message[],
  options: GameHistoryTrimmerOptions
): Promise<Message[]> {
  return worker.runGameTrimmer(messages, options);
}

export async function countTokens(messages: Message[]) {
  return await worker.countTokensForMessages(messages);
}
