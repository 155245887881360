import { OptionGroup } from "../core/options/option-group";
import { supportsSpeechRecognition } from "../core/speech-recognition-types";

export const whisperOptions: OptionGroup = {
    id: 'speech-recognition',
    name: "Speech Recognition",
    hidden: true,
    options: [
        {
            id: 'use-whisper',
            defaultValue: false,
            displayOnSettingsScreen: "speech",
            displayAsSeparateSection: false,
            renderProps: {
                type: "checkbox",
                label: "Use the OpenAI Whisper for speech recognition",
                hidden: true, //!supportsSpeechRecognition,
            },
        },
        {
            id: 'show-microphone',
            defaultValue: false,
            displayOnSettingsScreen: "speech",
            displayAsSeparateSection: false,
            renderProps: {
                type: "checkbox",
                label: "Show microphone in message input",
                hidden: true, //!supportsSpeechRecognition,
            },
        },
        {
            id: 'speech-model',
            defaultValue: 'whisper-1',
            displayOnSettingsScreen: "speech",
            displayAsSeparateSection: false,
            renderProps: {
                type: "select",
                label: "Transcription model",
                options: [
                    { label: "Whisper API", value: "whisper-1" },
                    { label: "Whisper Tiny", value: "tiny" },
                    { label: "Whisper Tiny - English", value: "tiny.en" },
                    { label: "Whisper Base", value: "base" },
                    { label: "Whisper Base - English", value: "base.en" },
                    { label: "Whisper Small", value: "small" },
                    { label: "Whisper Small - English", value: "small.en" },
                    { label: "Whisper Medium", value: "medium" },
                    { label: "Whisper Medium - English", value: "medium.en" },
                    { label: "Whisper Large", value: "large" },
                ],
            },
        },
        {
            id: 'speech-file-type',
            defaultValue: 'mp3',
            displayOnSettingsScreen: "speech",
            displayAsSeparateSection: false,
            renderProps: {
                hidden: true,
                type: "select",
                label: "Audio file type",
                options: [
                    { label: "MP3", value: "mp3" },
                    { label: "WAV", value: "wav" },
                ],
            },
        }
    ],
}