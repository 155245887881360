import styled from '@emotion/styled';
import { Alert } from '@mantine/core';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { TTSButton } from './tts-button';
import { v4 as uuidv4 } from 'uuid';
import { useAppContext } from '../core/context';

const Container = styled.div`
    // position: absolute;
    // left: 0;
    z-index: 1;
    text-align: center;

    .message-error {
    }
`;

interface MessageErrorProps {
    message: string | null;
    choices?: string[];
}

const MessageError: React.FC<MessageErrorProps> = ({ message, choices }) => {

    const context = useAppContext();
    const intl = useIntl();
    const [messageId, setMessageId] = useState(uuidv4());

    useEffect(() => {
        // Update messageId here
        setMessageId(uuidv4());
    }, [message, choices]); // Depend on message and choices

    const invalidChoiceMessage = intl.formatMessage({
        id: 'invalid-choice-message',
        defaultMessage: 'Please choose from the following:',
        description: 'Message displayed when the user selects an invalid choice',
    });

    const closeAlertLabel = intl.formatMessage({
        id: 'close-alert',
        defaultMessage: 'Dismiss',
        description: 'Label for close button on error message alert',
    });

    // when alert is closed, set the messageError to null
    const onAlertClose = () => {
        context.setMessageError(null);
    };

    const alertRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (alertRef.current) {
            alertRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    let shownMessage = message;
    let hiddenMessages : string[] = [];

    if (message === 'Invalid user choice' && choices) {

        shownMessage = invalidChoiceMessage;
        hiddenMessages = choices.map((choice, index) => {
            return (index + 1) + '. ' + choice + '.';
        });
    }

    if (!message) {
        return null;
    }

    // const messageErrorRef = React.useRef<HTMLDivElement>(null);
    
    // use font awesome icon for error message
    const iconclass = 'fa fa-exclamation-circle';
    return <Container>
        <div className={'message-error message-error-' + messageId}>
        <Alert ref={alertRef} icon={<i className={iconclass} />} color="yellow" 
            withCloseButton closeButtonLabel={closeAlertLabel} 
            onClose={onAlertClose}>
            <p>{shownMessage}</p>
        </Alert>
        {hiddenMessages.map((message) =>
            <p key={message} style={{display: 'none'}}>{message}</p>
        )}
        <TTSButton id={messageId}
            selector={'.message-error-' + messageId}
            complete={true}
            autoplay={true} 
            hidden={true} />
        </div>
    </Container>;
};

export default MessageError;