import { Game, OpenAIMessage, Parameters } from "../game/types";
import { OptionsManager } from "../options";

export interface PluginContext {
    getOptions(): any;
    getCurrentGame(): Game;
    createGameCompletion(messages: OpenAIMessage[], parameters: Parameters): Promise<string>;
    setGameTitle(title: string): Promise<void>;
}

export function createBasicPluginContext(pluginID: string, pluginOptions: OptionsManager, gameID?: string | null, game?: Game | null) {
    return {
        getOptions: (_pluginID = pluginID) => pluginOptions.getAllOptions(_pluginID, gameID),
        getCurrentGame: () => game,
        createGameCompletion: async () => '',
        setGameTitle: async (title: string) => { },
    } as PluginContext;
}
