import { MessageTree } from "./message-tree";

export interface Parameters {
    temperature: number;
    apiKey?: string;
    storyName?: string;
    model: string;
}

export interface Message {
    id: string;
    gameID: string;
    parentID?: string;
    timestamp: number;
    role: string;
    model?: string;
    content: string;
    parameters?: Parameters;
    done?: boolean;
    choices?: string[];
}

export interface TranscriptionParameters {
    apiKey?: string;
    model: string;
}

export interface UserSubmittedMessage {
    gameID: string;
    parentID?: string;
    content: string;
    requestedParameters: Parameters;
}

export interface OpenAIMessage {
    role: string;
    content: string;
}

export function getOpenAIMessageFromMessage(message: Message): OpenAIMessage {
    return {
        role: message.role,
        content: message.content,
    };
}

export interface Story {
    name: string;
    title: string;
    category: string;
    version: string;
    type?: "u" | "s";
    contentRating?: number;
    author?: string;
    description?: string;
    cover?: string;
    themeMusic?: string;
    voice?: string;
}
export interface Game {
    id: string;
    story: Story | null;
    messages: MessageTree;
    metadata?: Record<string, any>;
    pluginOptions?: Record<string, any>;
    title?: string | null;
    created: number;
    updated: number;
    deleted?: boolean;
}

export function serializeGame(game: Game): string {
    return JSON.stringify({
        ...game,
        messages: game.messages.serialize(),
    });
}

export function deserializeGame(serialized: string) {
    const game = JSON.parse(serialized);
    game.messages = new MessageTree(game.messages);
    return game as Game;
}