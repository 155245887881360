import { Button, FileButton } from "@mantine/core";
import { importGame } from "../../core/game/game-persistance";
import { Game, serializeGame } from "../../core/game/types";
import { useAppContext } from "../../core/context";
import SettingsOption from "./option";
import SettingsTab from "./tab";
import { useState, useCallback } from "react";

export default function UserOptionsTab(props: any) {
    const context = useAppContext();

    const doc = context.game.doc;
    const getData = useCallback(async () => {
        const games = context.game.all() as Game[];
        return games.map(game => serializeGame(game));
    }, [context.game]);

    const [importedGames, setImportedGames] = useState<number | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleExport = useCallback(async () => {
        const data = await getData();
        const json = JSON.stringify(data);
        const blob = new Blob([json], { type: "application/json" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "falkor.json";
        link.click();
    }, [getData]);

    const handleImport = useCallback(
        async (file: File) => {
            try {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const json = e.target?.result as string;
                    const data = JSON.parse(json) as Game[];
                    if (data.length > 0) {
                        context.game.doc.transact(() => {
                            for (const game of data) {
                                try {
                                    importGame(doc, game);
                                } catch (e) {
                                    console.error(e);
                                }
                            }
                        });
                        setImportedGames(data.length);
                        setErrorMessage(null);
                    } else {
                        setErrorMessage("The imported file does not contain any game data.");
                    }
                };
                reader.readAsText(file);
            } catch (error) {
                setErrorMessage("Failed to import game data.");
            }
        },
        [doc]
    );

    const successMessage = importedGames ? (
        <div style={{ color: 'green' }}>
            <i className="fa fa-check-circle"></i>
            <span style={{ marginLeft: '0.5em' }}>Imported {importedGames} game(s)</span>
        </div>
    ) : null;

    const errorMessageElement = errorMessage ? (
        <div style={{ color: 'red' }}>{errorMessage}</div>
    ) : null;

    return (
        <SettingsTab name="user">
            <SettingsOption heading="Export or import your game history">
                <div>
                    <Button variant="light" onClick={handleExport} style={{
                        marginRight: '1rem',
                    }}>Export</Button>
                    <FileButton onChange={handleImport} accept=".json">
                        {(props) => <Button variant="light" {...props}>Import</Button>}
                    </FileButton>
                </div>
                {successMessage}
                {errorMessageElement}
            </SettingsOption>
        </SettingsTab>
    );
}
